import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { Layer, SelectOption } from '@core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-layer-style-dialog',
  templateUrl: './layer-style-dialog.component.html',
  styleUrls: ['./layer-style-dialog.component.scss']
})
export class LayerStyleDialogComponent implements OnInit, DoCheck {

  @Input() layer?: Layer;
  @Output() opacityChange = new EventEmitter();
  @Output() styleChange = new EventEmitter();
  @Output() close = new EventEmitter();
  @Input() isVisible: boolean = false;


  opacity?: number;
  selectedStyle?: string;
  selectedTime?: string;

  faTimes = faTimes;

  constructor() {
    
  }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    if (this.layer) {
      if (this.layer.opacity !== undefined && this.layer.opacity !== null) {
        if (this.opacity !== this.layer.opacity) {
          this.opacity = this.layer.opacity;
          this.onOpacityChange();
        } else {
          if (this.layer.wmsParams.time && this.layer.wmsParams.time !== this.selectedTime) {
            this.opacity = this.layer.opacity;
            this.onOpacityChange();
            this.selectedTime = this.layer.wmsParams.time;
          }
        }
      } else {
        this.layer.opacity = 1;
        this.opacity = 1;
      }
      if (this.layer.selectedStyle) {
        if (this.selectedStyle !== this.layer.selectedStyle) {
          this.selectedStyle = this.layer.selectedStyle;
          this.onStyleChange();
        }
      } else {
        this.selectedStyle = this.layerStyles.length > 0 ? this.layerStyles[0].value?.toString() : '';
        this.layer.selectedStyle = this.selectedStyle;
      }
    }
  }

  onCancel() {
    this.close.emit();
  }

  get hasStyles() {
    return this.layerStyles.length > 0;

  }

  get layerStyles(): SelectOption[] {

    if (this.layer && this.layer.wmsParams) {
      if (this.layer?.wmsParams.styles) {
        const styles = this.layer?.wmsParams.styles.split(',');
        const options: SelectOption[] = [];
        styles.forEach((style) => {
          options.push({label: style, value: style});
        });
        return options;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  onOpacityChange() {
    this.opacityChange.emit(this.opacity);
  }

  onStyleChange() {
    this.styleChange.emit(this.selectedStyle);
  }

  get layerName() {
    if (this.layer) {
      return `${this.layer.layerKey}_${this.layer.simpleOrderId}`;
    } else {
      return '';
    }
  }

}
