<div class="fixed top-1/3 left-[30%] bg-white z-[999] w-5/12 rounded-lg border-2 border-primary-500">
    <div class="flex flex-row justify-center py-4 uppercase text-primary-500">
        {{title}}
    </div>
    <div 
        class="flex flex-col justify-center m-auto text-center p-4 text-secondary-500" 
        [innerHTML]="textContent"
    ></div>
    <div class="flex flex-row justify-center pb-2">
        <app-button
            [label]="confirmLabel"
            (click)="onConfirmClick()"
        ></app-button>
    </div>
</div>