import { Injectable } from '@angular/core';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  private currentLanguageId: string;
  private languageChangedSource = new Subject<string>();

  public currentObservableLanguage$ = this.languageChangedSource.pipe(
    map(data => data)
  );

  constructor(private ngxTranslateService: NgxTranslateService) {
    this.currentLanguageId = localStorage.getItem('lang_id') ?? 'pt';
  }

  get currentLanguage() {
    return this.currentLanguageId;
  }

  set currentLanguage(langId: string) {
    this.currentLanguageId = langId;
    this.ngxTranslateService.use(langId);
    this.emitLanguageChange(langId);

    localStorage.setItem('lang_id', langId);
  }

  emitLanguageChange(newLangId: string) {
    this.languageChangedSource.next(newLangId);
  }

  translate(slug: string) {
    return this.ngxTranslateService.instant(slug);
  }

  get(slug: string) {
    return this.ngxTranslateService.get(slug);
  }
}
