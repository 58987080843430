const LOCALE_ID = 'pt';

import { enGB, pt } from 'date-fns/locale';

const locales: { [key: string]: Locale } = {
  pt,
  en: enGB
};

export { LOCALE_ID, locales };
