import { Injectable } from '@angular/core';
import { Layer, TranslateService } from '@core';
import {
  addDays,
  differenceInHours,
  endOfDay,
  getDate,
  getMonth,
  getYear,
  parseISO,
  subDays
} from 'date-fns';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SeriesChartComponent } from 'shared/components/series-chart/series-chart.component';
import { toDms } from 'shared/helpers';
import { DataSet } from 'vis-data/esnext';
import { Timeline } from 'vis-timeline/esnext';
import { MapService, SnackbarService } from '..';
import * as colors from './../../../../assets/colors/shapefile-colors.json';
import * as tGradient from 'tinygradient';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TimelineService {
  public timelineSelectedSource$ = new BehaviorSubject<string | undefined>(
    undefined
  );
  componentDestroyed$: Subject<boolean> = new Subject();
  private timesteps: string[] = [];
  private items: any = new DataSet([]);
  public layersSelected?: Layer[] = [];
  private _currentTime?: string;
  private _currentUri?: string;
  private _currentTitle?: string;
  private _start?: Date;
  private _end?: Date;
  private minDate?: Date;
  private maxDate?: Date;
  private aoiDrawing = false;
  private rangeChanged = new Subject();
  private currentChangedSource = new Subject();
  private isStartToday = false;
  private startTodayChangedSource = new Subject();
  public timeSeriesActivatedSource = new Subject();
  public timeSeriesActivated$ = this.timeSeriesActivatedSource.asObservable();
  private container: HTMLElement | null = null;
  private timeline: { [key: string]: Timeline } = {};
  private options = {
    locale: this.translateService.currentLanguage,
    height: '110px',
    editable: {
      add: false,
      updateTime: true,
      updateGroup: false,
      remove: false
    },
    clickToUse: false,
    showMajorLabels: true,
    format: {
      minorLabels: {
        millisecond: 'SSS',
        second: 's',
        minute: 'HH:mm',
        hour: 'HH:mm',
        weekday: 'ddd D/M',
        day: 'MMM D',
        month: 'MMM',
        year: 'YYYY'
      },
      majorLabels: {
        millisecond:'HH:mm:ss YYYY',
        second:     'D MMMM HH:mm YYYY',
        minute:     'ddd D MMMM YYYY',
        hour:       'ddd D MMMM YYYY',
        weekday:    'MMMM YYYY',
        day:        'MMMM YYYY',
        week:       'MMMM YYYY',
        month:      'YYYY',
        year:       ''
      }
    }
  };

  private geoTimeline?: Map<string, Layer[]>;
  private timeseriesMap!: Map<string, boolean>;
  private timesourceSetMap: Map<string, boolean> = new Map();

  seriesCharts: SeriesChartComponent[] = [];

  private selectionMode: 'normal' | 'multi' = 'normal';
  private selectedTimes: string[] = [];

  private gradientColors?: any[];
  private colorpallet = JSON.parse(JSON.stringify(colors));
  private legendScaleRange?: number[];
  private legendScaleUnits?: string;
  private legendImageURL?: string;
  private legendVisible = false;
  private gradientLegendVisible = false;

  public layersState: {
    [key: string]: { active: boolean };
  } = {};

  private timelineThreshold: number = 50; // max number of points to display on the timeline

  constructor(
    private mapService: MapService,
    private translateService: TranslateService,
    private snackbarService: SnackbarService,
    private http: HttpClient
  ) {

    this.geoTimeline = new Map();
    this.timeseriesMap = new Map();

    this.mapService.timeseriesCompleted$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((res) => {
      if (res.length > 0) {
        this.seriesCharts[0].clear();
        res.forEach(([layerSources, series]) => {
          const layerName: string = (layerSources[0] as any).options.layers;
          this.seriesCharts[0].addParameter(layerName, layerName, '', series);
        });
      } else {
        const title = this.translateService.translate('main-page.snackbars.danger.timeseries.title');
        const message = this.translateService.translate('main-page.snackbars.danger.timeseries.message');
        this.snackbarService.danger(title, message).during(5000).show();
      }
      
      if (this.seriesCharts[0].numberOfParameters > 0) {
        if (this.mapService.timeseriesMarkerLatLngs) {
          const coords = this.mapService.timeseriesMarkerLatLngs;
          const title = toDms(coords.lat, coords.lng).lat + ', ' + toDms(coords.lat, coords.lng).lng;
          this.seriesCharts[0].newChartTitle = title;
        }
        this.seriesCharts[0].drawChart();
      }
    });

    this.mapService.ncwmsLegendDataComplete$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((data) => {
      if (data[0] && data[1] && data[2]) {
        this.legendImageURL = data[0];
        this.legendScaleRange = data[1];
        this.legendScaleUnits = data[2];
        this.legendVisible = data[3];
      } else {
        this.legendImageURL = undefined;
        this.legendScaleRange = undefined;
        this.legendScaleUnits = undefined;
        this.legendVisible = false;
      }
    });

    this.mapService.topLayerChanged$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((topLayer: string) => {
      this.layersSelected = this.geoTimeline?.get(topLayer);
      if (this.layersSelected) {
        this.setupTimeline(topLayer);
        /* CHEAT CODE BEGIN
        This code below is to force the "rangeChanged" event,
        in order to setup the correct timestamps. This should be looked at
        and changed in the future.
        */
        this.zoomIn(topLayer);
        setTimeout(() => {
          this.zoomOut(topLayer);
        }, 500); 
        /* CHEAT CODE END */
      }
    });
  }

  public getLayers() {
    return this.geoTimeline?.values();
  }

  public layers: { [key: string]: Layer[] } = {};

  public addLayer(layer: Layer, isTimeSeries: boolean) {
    const timelineLayerKey = `${layer.layerKey}!${layer.orderID}`
    this.layersState[timelineLayerKey] = {
      active: true
    };

    this.timeseriesMap.set(timelineLayerKey, isTimeSeries);

    if (!this.layers[timelineLayerKey]) this.layers[timelineLayerKey] = [];
    const index = this.layers[timelineLayerKey].findIndex(
      ({ title }) => title === layer.title
    );

    if (index > -1) this.layers[timelineLayerKey].splice(index, 1);

    this.layers[timelineLayerKey] = [...this.layers[timelineLayerKey], layer];

    this.geoTimeline?.set(timelineLayerKey, this.layers[timelineLayerKey]);
  }

  public destroyLayer(layerKey: string, orderID: number) {
    const timelineLayerKey = `${layerKey}!${orderID}`
    this.timesourceSetMap.set(timelineLayerKey, false);
    delete this.layersState[timelineLayerKey];

    this.geoTimeline?.delete(timelineLayerKey);
    this.timeseriesMap.delete(timelineLayerKey);
    delete this.layers[timelineLayerKey];
  }

  public setTimeSource(dateTime: string) {
    if (this.selectionMode === 'normal') {
      let hasData: boolean = false;
      this.layersSelected?.forEach(layer => {
        const currentSimpleId = `${layer.layerKey}!${layer.orderID}`;
        const currentId = `${layer.layerKey}!${layer.orderID}!${layer.dateTime}`;
        this.mapService.destroyLayer(`${layer.layerKey}!${layer.orderID}!${layer.dateTime}`);
        if (layer.dataDissemination) {
          if (layer.geoServerURI) {
            hasData = true;
          }
          const time = dateTime.replace(' ', 'T');
          this._currentUri = `${layer.downloadURI}${time}`;
          this._currentTitle = layer.title;

          layer.wmsParams.time = time;
          
          this.timesourceSetMap.set(currentSimpleId, true);
          this.mapService.addGeoTiff(currentId, layer);
          // this.mapService.styleLayer = layer;
          // this.mapService.styleLayerId = currentSimpleId;
        } else {
          if (layer.dateTime === dateTime) {
            const productArray = new URL(layer.downloadURI).pathname.split('storage/');

            let productName;
            let gradientColorList
            if (productArray[1]) {
              productName = productArray[1].split('/')[0];
              gradientColorList = this.colorpallet[productName];
              this.gradientColors = gradientColorList;
            }
            if (layer.geoServerURI) {
              hasData = true;
              if (layer.geoServerURI.split('/').includes('reflect') && !layer.wmsParams.styles && gradientColorList) {
                this.gradientLegendVisible = true;
                const gradient = tGradient(gradientColorList).rgb(layer.timesteps.length);
                let shapeColor: string | undefined;
                const timeIndex = layer.timesteps.sort().indexOf(dateTime);
                timeIndex > -1 ? shapeColor = gradient[timeIndex].toHex() : shapeColor = undefined;
                const wfsUrl = this.mapService.getWFSUrl(layer.geoServerURI, layer);
                this.mapService.getWFSGeoJSON(wfsUrl).pipe(take(1)).subscribe((res) => {
                  this.timesourceSetMap.set(currentSimpleId, true);
                  this.mapService.addGeoTiff(currentId, layer, false, res, shapeColor);
                });
              } else {
                this.gradientLegendVisible = false;
                this.timesourceSetMap.set(currentSimpleId, true);
                this.mapService.addGeoTiff(currentId, layer);
              }
            }

            // this.mapService.styleLayer = layer;
            // this.mapService.styleLayerId = currentSimpleId;
            this._currentUri = layer.downloadURI;
            this._currentTitle = layer.title;
          }
        }
      });
      if (!hasData) {
        const title = this.translateService.translate('main-page.snackbars.danger.geoserver-uri.title');
        const message = this.translateService.translate('main-page.snackbars.danger.geoserver-uri.message');
        this.snackbarService.danger(title, message).during(4000).show();
      }
    } else {
      const existingTime = this.selectedTimes.find((dt) => new Date(dt).getTime() === new Date(dateTime).getTime());
      if (existingTime) {
        this.layersSelected?.forEach(layer => {
          if (new Date(layer.dateTime).getTime() === new Date(existingTime).getTime()) {
            this.mapService.destroyLayer(`${layer.layerKey}!${layer.orderID}!${layer.dateTime}`);
            this.selectedTimes.splice(this.selectedTimes.indexOf(existingTime), 1);
          }
        });
      } else {
        let hasData: boolean = false;
        this.layersSelected?.forEach(layer => {
          let currentId: string;
          let currentSimpleId: string;
          if (layer.dataDissemination) {
            if (layer.geoServerURI) {
              hasData = true;
            }
            const time = dateTime.replace(' ', 'T');
            this._currentUri = `${layer.downloadURI}${time}`;
            this._currentTitle = layer.title;
            
            layer.wmsParams.time = time;
            currentId = `${layer.layerKey}!${layer.orderID}!${time}`;
            currentSimpleId = `${layer.layerKey}!${layer.orderID}`;
            this.timesourceSetMap.set(currentSimpleId, true);
            this.mapService.addGeoTiff(currentId, layer, true);
          } else {
            if (layer.dateTime === dateTime) {
              currentId = `${layer.layerKey}!${layer.orderID}!${layer.dateTime}`;
              currentSimpleId = `${layer.layerKey}!${layer.orderID}`;

              const productName = new URL(layer.downloadURI).pathname.split('storage/')[1].split('/')[0];
              const gradientColorList = this.colorpallet[productName];
              this.gradientColors = gradientColorList;
  
              if (layer.geoServerURI) {
                hasData = true;
                if (layer.geoServerURI.split('/').includes('reflect') && !layer.wmsParams.styles && gradientColorList) {
                  this.gradientLegendVisible = true;
                  const gradient = tGradient(gradientColorList).rgb(layer.timesteps.length);
                  let shapeColor: string | undefined;
                  const timeIndex = layer.timesteps.sort().indexOf(dateTime);
                  timeIndex > -1 ? shapeColor = gradient[timeIndex].toHex() : shapeColor = undefined;
                  const wfsUrl = this.mapService.getWFSUrl(layer.geoServerURI, layer);
                  this.mapService.getWFSGeoJSON(wfsUrl).pipe(take(1)).subscribe((res) => {
                    this.timesourceSetMap.set(currentSimpleId, true);
                    this.mapService.addGeoTiff(currentId, layer, false, res, shapeColor);
                  });
                } else {
                  this.gradientLegendVisible = false;
                  this.timesourceSetMap.set(currentSimpleId, true);
                  this.mapService.addGeoTiff(currentId, layer);
                }
              }

              this._currentUri = layer.downloadURI;
              this._currentTitle = layer.title;
            }
          }
        });
        if (!hasData) {
          const title = this.translateService.translate('main-page.snackbars.danger.geoserver-uri.title');
          const message = this.translateService.translate('main-page.snackbars.danger.geoserver-uri.message');
          this.snackbarService.danger(title, message).during(4000).show();
        }
        this.selectedTimes.push(dateTime);
      }
    }
  }

  public initializeTimeLine(id: string) {
    this.gradientLegendVisible = false;
    this.container = document.getElementById(
      `visualization-${id}`
    ) as HTMLElement;

    this.timeline[id] = new Timeline(this.container, this.items, this.options);
    this.layersSelected = this.geoTimeline?.get(id);

    this.timeline[id].on('rangechanged', properties => {

      // according to the "event" property, adjust timesteps displayed in case the total layer timesteps exceeds a threshold.
      this.destroyAllTimesteps()
      const timeDifference = (properties.end - properties.start) / 1000 / 86400;
      this.addTimesteps(properties.start, properties.end, timeDifference);
      let day: number;
      let month: number;
      let lastAddedItem: {
        start: any;
        id?: string;
        type?: string;
        editable?: boolean;
        className: string;
      };
      let datesDifferenceInHours;
      if (this.timesteps.length === 1)
        this.timesteps.forEach(step => this.timesteps.push(step));
      this.timesteps.forEach((step, index) => {
        const date = new Date(step);
        if (getMonth(date) !== month) month = getMonth(date);
        if (
          getDate(date) !== day &&
          this.timesteps.indexOf(step) !== this.timesteps.length - 1
        ) {
          day = getDate(date);
          this.items.add({
            id: index,
            start: new Date(getYear(date), month, day, 0, 0, 0),
            end: new Date(getYear(date), month, day, 23, 59, 59),
            type: 'background',
            className: 'primary'
          });
          lastAddedItem = {
            id: step,
            start: step,
            type: 'point',
            editable: false,
            className: 'primary-'
          };
          this.items.add(lastAddedItem);
        }
        datesDifferenceInHours = differenceInHours(
          date,
          parseISO(lastAddedItem.start)
        );
        if (datesDifferenceInHours > timeDifference / 1.5) {
          lastAddedItem = {
            id: step,
            start: step,
            type: 'point',
            editable: false,
            className: 'primary'
          };
          this.items.add(lastAddedItem);
        }
      });
    });
    this.timeline[id].on('select', properties => {
      const { items } = properties;
      items.forEach((item: string) => {
        if (item) {
          const date = item.replace('T', ' ');
          try {
            this.timeline[id].removeCustomTime('timeline');
            this.timeline[id].addCustomTime(date, 'timeline');
          } catch (err) {
            this.timeline[id].addCustomTime(date, 'timeline');
          }
          this.setTimeSource(item);
          this._currentTime = item;
        }
        if (this.selectionMode === 'multi') {
          this.timeline[id].setSelection(this.selectedTimes);
        }
      });
    });
    this.setupTimeline(id);
  }

  removeCurrentUri() {
    this._currentUri = undefined;
    this._currentTitle = undefined;
  }

  removeAllTimesteps() {
    this.items.forEach((item: { id: string }) => {
      if (item.id !== 'animation-start' && item.id !== 'animation-end') {
        this.items.remove(item.id);
      }
    });
  }

  destroyAllTimesteps() {
    this.timesteps = [];
    this.removeAllTimesteps();
    this.removeCurrentUri();
    this.reset();
  }

  setupTimeline(id: string) {
    this.destroyAllTimesteps();
    this.addTimesteps()
    const timesteps = this.timesteps.map(step => new Date(step));

    let daysBuffer;
    if (Math.abs(this.dateDiffInDays(timesteps[timesteps.length - 1], timesteps[0])) < 60) {
      daysBuffer = 3;
    } else if(Math.abs(this.dateDiffInDays(timesteps[timesteps.length - 1], timesteps[0])) > 3652) {
      daysBuffer = 90;
    } else {
      daysBuffer = 30;
    }
    const start = subDays(timesteps[0], daysBuffer);
    const end = addDays(timesteps[timesteps.length - 1], daysBuffer);

    this.timeline[id].setOptions({
      start,
      min: start,
      end,
      max: end
    });
    this.setMinMaxDates();
  }


  dateDiffInDays(a: Date, b: Date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;

    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  setMinMaxDates() {
    this.minDate = new Date(this.timesteps[0]);
    this.maxDate = endOfDay(
      new Date(this.timesteps[this.timesteps.length - 1])
    );
  }

  public zoomIn(id: string) {
    this.timeline[id].zoomIn(0.5);
  }

  public zoomOut(id: string) {
    this.timeline[id].zoomOut(0.5);
  }

  get start(): Date | undefined {
    return this._start;
  }

  set start(date: Date | undefined) {
    if (!this._start || date?.getTime() !== this._start.getTime()) {
      this._start = date;
      if (this._end) {
        this.rangeChanged.next([this._start, this._end]);
      }
    }
  }

  get end(): Date | undefined {
    return this._end;
  }

  set end(date: Date | undefined) {
    if (!this._end || date?.getTime() !== this._end.getTime()) {
      this._end = date;
      if (this._start) {
        this.rangeChanged.next([this._start, this._end]);
      }
    }
  }

  reset() {
    this._start = undefined;
    this._end = undefined;
  }

  newRange() {
    if (this._start && this._end) {
      this.rangeChanged.next([this._start, this._end]);
    }
  }

  get currentTime(): string | undefined {
    return this._currentTime;
  }

  get currentUri(): string | undefined {
    return this._currentUri;
  }

  set currentUri(date: string | undefined) {
    this._currentUri = date;
  }

  set currentTime(date: string | undefined) {
    this._currentTime = date;
    this.currentChangedSource.next(date);
  }

  set timelineStartToday(newValue: boolean) {
    this.isStartToday = newValue;
    this.startTodayChangedSource.next(newValue);
  }

  get timelineStartToday(): boolean {
    return this.isStartToday;
  }

  set aoiDrawingEnabled(flag: boolean) {
    this.aoiDrawing = flag;
  }

  get aoiDrawingEnabled() {
    return this.aoiDrawing;
  }

  toggleTimeseriesPlot() {
    this.mapService.getTimeSeriesPlot = !this.mapService.getTimeSeriesPlot;
    if (!this.mapService.getTimeSeriesPlot) {
      this.mapService.removeTimeseriesPlotMarker();
    }
  }

  closeTimeseriesPlot() {
    this.mapService.getTimeSeriesPlot = false;
  }

  get isPlotDrawingActive() {
    return this.mapService.getTimeSeriesPlot;
  }

  get topLayer() {
    return this.mapService.topLayer;
  }

  get topTimeline(){
    return this.mapService.topTimeline;
  }

  animateLayer(layerKey: string, timeInterval: string, frameRate: number) {
    const layer = this.layers[layerKey];
    if (layer && layer.length > 0) {
      const currentId = `${layerKey}!${layer[0].dateTime}`;
      this.mapService.addAnimatedLayer(layer[0], currentId, timeInterval, frameRate);
    }
  }

  stopAnimatedLayer(layerKey: string) {
    const layer = this.layers[layerKey];
    if (layer && layer.length > 0) {
      const currentId = `${layerKey}!${layer[0].dateTime}`;
      this.mapService.removeAnimatedLayer(currentId);
    }
    
  }

  get isAnimationActive() {
    return this.mapService.isAnimationActive;
  }

  get isAnimationLoading() {
    return this.mapService.animationLoading;
  }

  public isTimeSeries(timelineLayerKey: string) {
    return this.timeseriesMap.get(timelineLayerKey);
  }

  serieschartDateChange(event: any) {
    if (event[0] && event[1]) {
      this.mapService.updateTimeseries(event[0], event[1]);
    }
  }

  serieschartClose() {
    this.mapService.removeTimeseriesPlotMarker();
    this.mapService.getTimeSeriesPlot = false;
    this.mapService.clearChartDates();
  }

  toggleSelectionMode(id: string) {
    if (this.selectionMode === 'normal') {
      this.selectionMode = 'multi';
      this.layersSelected?.forEach((layer) => {
        this.mapService.destroyLayer(`${layer.layerKey}!${layer.orderID}!${layer.dateTime}`);
      });
      this.timeline[id].setSelection([]);
      this._currentTime = undefined;
      this.timeline[id].removeCustomTime('timeline');
    } else if (this.selectionMode === 'multi') {
      this.selectionMode = 'normal';
      this.layersSelected?.forEach((layer) => {
        if (layer.dataDissemination) {
          this.selectedTimes.forEach((time) => {
            this.mapService.destroyLayer(`${layer.layerKey}!${layer.orderID}!${time}`);
          });
        } else {
          this.mapService.destroyLayer(`${layer.layerKey}!${layer.orderID}!${layer.dateTime}`);
        }
      });
      this.timeline[id].setSelection([]);
      this.timeline[id].removeCustomTime('timeline');
      this._currentTime = undefined;
      this.selectedTimes = [];
    }
  }
  
  get activeSelectionMode() {
    return this.selectionMode;
  }

  get numSelectedTimes() {
    if (this.selectedTimes) {
      return this.selectedTimes.length;
    } else {
      return 0;
    }
  }

  get ncwmsLegendURL() {
    return this.legendImageURL;
  }

  get ncwmsScaleRange() {
    return this.legendScaleRange;
  }

  get ncwmsScaleUnits() {
    return this.legendScaleUnits;
  }

  get isLegendVisible() {
    return this.legendVisible;
  }

  get isGradientLegendVisible() {
    return this.gradientLegendVisible;
  }

  get gradientColorList() {
    return this.gradientColors;
  }

  addTimesteps(start?: Date, end?: Date, timeDifference?: number) {
    
    if (start && end && timeDifference) {
      this.layersSelected?.forEach((layer) => {
        const layerTimesteps = layer.timesteps.sort();
        if (layerTimesteps.length > this.timelineThreshold) {
          const timeSubsteps = this.splitTimesteps(layerTimesteps, start, end);
          const timeSubstepsFixed:string[] = [];
          timeSubsteps.map(value => {
            timeSubstepsFixed.push(value.replace(':00Z', ':00.000Z'))
          })
          this.timesteps.push(...timeSubstepsFixed);
        } else {
          const layerTimestepsFixed:string[] = [];
          layerTimesteps.map(value => {
            layerTimestepsFixed.push(value.replace(':00Z', ':00.000Z'))
          })
          this.timesteps.push(...layerTimestepsFixed);
        }
      });
    } else {
      this.layersSelected?.forEach((layer) => {
        const layerTimesteps = layer.timesteps.sort();
        if (layerTimesteps.length > this.timelineThreshold) {
          const step = Math.floor(layerTimesteps.length / this.timelineThreshold);
          this.timesteps.push(...layerTimesteps.filter((timestep, index) => {
            return (index % step) === 0;
          }
          ));
          if (this.timesteps[this.timesteps.length - 1] !== layerTimesteps[layerTimesteps.length - 1]) {
            this.timesteps.push(layerTimesteps[layerTimesteps.length - 1]);
          }
        } else {
          this.timesteps.push(...layerTimesteps);
        }
        
      });
    }

    
    this.timesteps = this.timesteps.sort();
  }

  splitTimesteps(timesteps: string[], start: Date, end: Date) {
    const dateTimesteps = timesteps.map((timestep) => new Date(timestep));
    const timeSubTemp = dateTimesteps.filter((timestep) =>  timestep > start);
    const timeSub = timeSubTemp.filter((timestep) => timestep < end);
    let step: number;
    if (timeSub.length <= this.timelineThreshold) {
      step = 1;
    } else {
      step = Math.floor(timeSub.length / this.timelineThreshold);
    }
    const timeSubStep = timeSub.filter((timestep, index) => {
      return index % step === 0;
    });
    return timeSubStep.map((timestep) => timestep.toISOString());
  }

  isTimeSourceSet(layerKey: string, complexOrderId: number): boolean {
    const id = layerKey + '!' + complexOrderId.toString();
    return this.timesourceSetMap.get(id) ?? false;
  }

  changeTimelineLocale(language: string) {
    this.options.locale = language;
  }

  destroyTimeline(id: string) {
    this.timeline[id].destroy();
  }

  downloadFile(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: 'text/plain;charset=UTF-8',
      })
    });
  }

  get currentTitle(): string | undefined {
    return this._currentTitle;
  }
}
