import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  MapService,
  NewOrder,
  OrdersService,
  SnackbarService,
  TranslateService
} from '@core';
import { IMyDateRangeModel } from 'angular-mydatepicker';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { parseFromWK } from 'wkt-parser-helper';

@Component({
  selector: 'menu-service-dialog',
  templateUrl: './menu-service-dialog.component.html',
  styleUrls: ['./menu-service-dialog.component.scss']
})
export class MenuServiceDialogComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  executionDate?: IMyDateRangeModel;
  isModalOpen: boolean = true;
  isOrderLoading: boolean = false;

  @Input() open: boolean = false;
  @Input() service: any = {};
  @Input() ordersDisabled: boolean = false;
  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() orderMade = new EventEmitter();
  @Output() tabChanged = new EventEmitter<string>();

  id: string | null = null;
  activeTab: 'details' | 'instructions' | 'order' = 'details';

  tabs: ['details', 'instructions', 'order'] = [
    'details',
    'instructions',
    'order'
  ];

  constructor(
    private mapService: MapService,
    private ordersService: OrdersService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.insertFootprintOnMap(100);
  }

  ngOnDestroy(): void {
    this.mapService.clearMap();
    this.mapService.clearFootprint();
  }

  onConfirm() {
    this.confirm.emit();
  }

  onCancel() {
    this.cancel.emit();
  }

  tabClasses(activeTab: string) {
    return this.activeTab === activeTab ? 'border-primary ' : '';
  }

  drawOnMap(typeOfDraw: 'polygon' | 'marker') {
    this.mapService.drawOnMap(typeOfDraw, 'order');
  }

  makeOrder(order: NewOrder) {
    this.isOrderLoading = true;
    this.ordersService
      .createNewOrder(order)
      .pipe(finalize(() => (this.isOrderLoading = false)))
      .subscribe(
        data => {
          const title = this.translateService.translate(
            'main-page.snackbars.success.order.title'
          );
          const message = this.translateService.translate(
            'main-page.snackbars.success.order.message'
          );

          this.snackbarService
            .success(`${title}${data.id}`, message)
            .during(5000)
            .show();

          this.orderMade.emit();
        },
        error => {
          if (error.error.target) {
            this.snackbarService
                .danger(
                    'Something went wrong with ' + error.error.target,
                    error.error.message
                )
                .during(5000)
                .show();
          } else {
            this.snackbarService
                .danger(
                    'Unexpected Error!',
                    'Sorry, unexpected error while executing the order.'
                )
                .during(5000)
                .show();
          }
        }
      );
  }

  get isGoodToGo() {
    if (this.service?.serviceType.toLowerCase() === 'data_collection') {
      return !!(this.executionDate?.beginDate && this.executionDate.endDate);
    }
    return !!(
      this.mapService.geoWkt !== null &&
      this.executionDate?.beginDate &&
      this.executionDate.endDate
    );
  }

  insertFootprintOnMap(timeout: number) {
    setTimeout(() => {
      if (this.service?.serviceFootprint) {
        let footprint = parseFromWK(this.service.serviceFootprint);

        this.mapService.setFootprint(footprint).finally();
      }
    }, timeout);
  }

  onReceiveData(data: NewOrder) {
    this.makeOrder(data);
  }

  changeTab(tab: 'details' | 'instructions' | 'order') {
    this.activeTab = tab;
    this.tabChanged.emit(tab);
  }
}
