import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-default-card',
  templateUrl: './default-card.component.html',
  styleUrls: ['./default-card.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class DefaultCardComponent implements OnInit {
  @Input() isSidebarCollapsed: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  collapsedClass() {
    return this.isSidebarCollapsed ? 'left-48' : 'left-20';
  }
}
