import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faDrawPolygon,
  faICursor,
  faMapPin,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
import { MapService, SnackbarService, TranslateService } from '@core';
import { parseFromWK } from 'wkt-parser-helper';
import { AreaOfInterestInput } from '../classes';
import * as turf from '@turf/turf';
import {
  takeUntil
} from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dynamic-input-aoi',
  templateUrl: './dynamic-input-aoi.component.html',
  styleUrls: ['./dynamic-input-aoi.component.scss']
})
export class DynamicInputAOIComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  @Input() input!: AreaOfInterestInput;
  @Input() form!: UntypedFormGroup;
  @Input() allowedGeometry: string | null | undefined;

  faDrawPolygon: IconDefinition = faDrawPolygon;
  faMapPin: IconDefinition = faMapPin;
  faPencil: IconDefinition = faPencilAlt;
  faICursor: IconDefinition = faICursor;
  isShowingWKT: boolean = false;
  isActive: boolean = false;

  constructor(
    private mapService: MapService,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}
  ngOnDestroy(): void {
    this.mapService.destroyDrawingLayer();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  get isValid() {
    return this.form.controls[this.input.key].hasError('invalidAOI');
  }

  ngOnInit(): void {
    this.mapService.drawingLayer$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        if (this.mapService.drawingContext === 'aoi-select') {
          this.form.get('aoi')?.setValue(res?.wkt);
          this.validateAOI();
        }
      });
  }

  onClcik(type: string) {
    if (type === 'wkt') {
      this.isShowingWKT = true;
    } else {
      this.isShowingWKT = false;
      this.form.get('aoi')?.setValue(null);
      this.mapService.drawOnMap(type, 'aoi-select');
    }
  }

  insertWKT(event: Event) {
    let wkt = (event.target as HTMLInputElement).value;
    this.form.controls[this.input.key].setValue(wkt);
    this.validateAOI();
    let aoi = parseFromWK(wkt);
    this.mapService.populateMap(aoi, '#ff0000').finally();
  }

  validateAOI() {
    if (
      this.input.serviceFootprint &&
      this.form.controls[this.input.key].value
    ) {
      if(parseFromWK(this.form.controls[this.input.key].value) as turf.Geometry){
        if (
          !turf.booleanWithin(
            parseFromWK(
              this.form.controls[this.input.key].value
            ) as turf.Geometry,
            parseFromWK(this.input.serviceFootprint) as turf.Geometry
          )
        ) {
          this.isActive = true;
          this.snackbarService
          .danger(
            this.translate.translate('alerts.title.error'),
            this.translate.translate(
              'alerts.description.outside-of-footprint'
            )
          )
          .during(4000)
          .show();
          this.form.controls[this.input.key].setErrors({ invalidAOI: true });
          this.form.controls[this.input.key].markAsDirty();

         
        } else {
          let geometry = parseFromWK(this.form.controls[this.input.key].value) as turf.Geometry;
          if(this.allowedGeometry === null || this.allowedGeometry === geometry.type.toLowerCase()) {
            this.isActive = false;
            this.snackbarService.isSnackbarActive$.next(false);
          } else {
            this.isActive = false;
            this.snackbarService.isSnackbarActive$.next(false);
              this.snackbarService
                .danger(
              this.translate.translate('alerts.title.error'),
              this.translate.translate(
                'alerts.description.geometry-not-available'
              )
            )
            .during(4000)
            .show();
      
            this.form.controls[this.input.key].setErrors({ invalidAOI: true });
            this.form.controls[this.input.key].markAsDirty();
          }   
        }
      } else {
        this.isActive = false;
        this.snackbarService.isSnackbarActive$.next(false);
        this.snackbarService
            .danger(
              this.translate.translate('alerts.title.error'),
              this.translate.translate(
                'alerts.description.invalid-wkt'
              )
            )
            .during(2000)
            .show();
            this.form.controls[this.input.key].setErrors({ invalidAOI: true });
            this.form.controls[this.input.key].markAsDirty();
      }
    }
  }

  disableButton(geometry: string): boolean{
    return !(this.allowedGeometry === null || this.allowedGeometry === geometry);
  }
}
