<div [formGroup]="form" class="mt-4">
  <label [attr.for]="input.key"
    >{{ input.label | translate
    }}<span *ngIf="!isValid" class="text-danger-100">* </span></label
  >
  <div class="flex gap-4 mt-2 mb-4">
    <app-button
      (click)="onClcik('polygon')"
      [leftIcon]="faDrawPolygon"
      [label]="'actions.draw-polygon' | translate"
      [isDisabled]="disableButton('polygon')"
    ></app-button>
    <app-button
      (click)="onClcik('rectangle')"
      [leftIcon]="faDrawPolygon"
      [label]="'actions.draw-rectangle' | translate"
      [isDisabled]="disableButton('polygon')"
    ></app-button>
    <app-button
      (click)="onClcik('marker')"
      [leftIcon]="faMapPin"
      [label]="'actions.mark-point' | translate"
      [isDisabled]="disableButton('point')"
    ></app-button>
    <app-button
      (click)="onClcik('line')"
      [leftIcon]="faPencil"
      [label]="'actions.draw-line' | translate"
      [isDisabled]="disableButton('linestring')"
    ></app-button>
    <app-button
      (click)="onClcik('wkt')"
      [leftIcon]="faICursor"
      [label]="'actions.insert-wkt' | translate"
    ></app-button>
  </div>
  <input
    (blur)="insertWKT($event)"
    *ngIf="isShowingWKT"
    class="
      focus:outline-none
      block
      w-full
      rounded-md
      bg-gray-100
      border border-transparent
      focus:ring-primary focus:bg-white focus:ring-1
      py-2
      px-3
    "
  />
</div>
