<div class="disable-background" *ngIf="ordersDisabled"></div>
<default-dialog [open]="open" (cancel)="onCancel()"
                (confirm)="onConfirm()">
  <h1 class="font-bold text-xl text-secondary">{{ service.title }}</h1>
  <div class="flex justify-between mt-8 mb-8">
    <button
      *ngFor="let tab of tabs"
      (click)="changeTab(tab)"
      [ngClass]="tabClasses(tab)"
      class="
        text-sm
        uppercase
        duration-300
        flex-1
        px-8
        py-2
        hover:border-primary
        border-b-2
      "
    >
      {{ "main-page.orders." + tab | translate }}
    </button>
  </div>
  <ng-container>
    <app-service-details
      *ngIf="activeTab === 'details'"
      [created]="service?.created"
      [description]="service?.description"
      [documentationURI]="service?.documentationURI"
      [keywords]="service?.keywords"
      [provider]="service?.serviceProvider"
      [type]="service?.serviceType"
    ></app-service-details>
    <app-service-instructions
      *ngIf="activeTab === 'instructions'"
    ></app-service-instructions>
    <app-make-order
      (data)="onReceiveData($event)"
      *ngIf="activeTab === 'order'"
      [isLoading]="isOrderLoading"
      [serviceId]="service?.id"
      [serviceType]="service?.serviceType"
      [serviceFootprint]="service?.serviceFootprint"
      [servicePeriod]="service?.servicePeriod"
      [allowedGeometry]="service?.allowedGeometry"
      [isSubscription]="service?.isSubscription"
    ></app-make-order>
  </ng-container>
</default-dialog>
