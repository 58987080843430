import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputService, NewOrder } from '@core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faDrawPolygon, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DynamicInputBase } from '../dynamics/dynamic-input-base';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-make-order[serviceId]',
  templateUrl: './make-order.component.html',
  styleUrls: ['./make-order.component.scss']
  // providers: [InputService]
})
export class MakeOrderComponent implements OnInit {
  isSignedOn$ = new Subject();
  faDrawPolygon: IconDefinition = faDrawPolygon;
  faMapPin: IconDefinition = faMapPin;

  @Input() isLoading: boolean = false;

  @Input() servicePeriod?: {
    serviceStart: Date;
    serviceStop: Date;
  };

  @Input() serviceFootprint?: string;

  @Input() serviceId?: string;
  @Input() serviceType?: string;
  @Input() allowedGeometry?: string;
  @Input() isSubscription?: boolean;

  @Output() closeModal = new EventEmitter();
  @Output() data = new EventEmitter<NewOrder>();

  inputs: DynamicInputBase<string>[] = [];
  inputs$?: Observable<DynamicInputBase<string>[]>;

  get hasData() {
    return this.inputs.length > 0 && this.servicePeriod;
  }

  constructor(private inputService: InputService) {}

  ngOnInit(): void {
    this.isSignedOn$.subscribe(isSignedOn => {
      if (isSignedOn) {
        if (!this.serviceId) {
          throw new TypeError('serviceID is required');
        } else {
          this.inputs$ = this.inputService.getInputs(
            this.serviceId,
            this.serviceType,
            this.servicePeriod,
            this.serviceFootprint
          );
        }
        if (this.inputs$)
          this.inputs$
            .pipe(take(1))
            .subscribe(dynamicInputs => (this.inputs = dynamicInputs));
      }
    });
  }

  onAuthorizationChange(isSignedOn: boolean) {
    this.isSignedOn$.next(isSignedOn);
  }

  emitData(data: any) {
    if (this.serviceId) data.serviceId = this.serviceId;
    this.data.emit(data);
  }

  get storeURL(): string {
    return environment.storeUrl ?? undefined
  }
}
