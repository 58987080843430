import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventOption } from '@core';
import {
  faSearch,
  faTimes,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { isEmptyOrSpaces } from 'shared/helpers';

const icons: { [key: string]: IconDefinition } = {
  clear: faTimes,
  search: faSearch
};

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  faSearch = faSearch;
  isEmptyOrSpaces = isEmptyOrSpaces;
  constructor() {}

  @Input() id!: string;
  @Input() type: string = 'text';
  @Input() label?: string;
  @Input() disabled: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() placeholder: string = '';
  @Input() value?: string;
  @Input() error: boolean = false;
  @Input() classes = 'py-2';
  @Input() labelSize = 'text-md';
  @Input() icon?: IconDefinition;

  @Output() inputChange = new EventEmitter<EventOption>();
  @Output() inputClear = new EventEmitter<EventOption>();
  @Output() search = new EventEmitter();

  ngOnInit(): void {}

  get isValid() {
    return !isEmptyOrSpaces(this.value?.toString());
  }

  onInputChange(event: EventOption) {
    this.value = event.value;
    if (!event.value) this.inputChange.emit(event);
  }

  onInputClear() {
    this.inputClear.emit();
  }

  onSearch() {
    if (this.isValid) this.search.emit({ id: this.id, value: this.value });
  }
}
