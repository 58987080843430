<div class="flex flex-col h-screen">
  <app-navbar
    class="z-40"
    [lang]="lang"
    (login)="login()"
    (logout)="logout()"
    (currentLang)="setLang($event)"
    [isAuthenticated]="isAuthenticated$ | async"
  ></app-navbar>
  <main class="flex-grow bg-gray-200">
    <ng-content></ng-content>
  </main>
  <app-footer class="z-40"></app-footer>
  <!-- add snackbar to whole project -->
  <app-snackbar></app-snackbar>
</div>
