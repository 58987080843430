<div class="mb-4">
  <label class="block text-gray-700 mb-2 {{ labelSize }}" [for]="id">
    {{ label }}
  </label>
  <div class="relative">
    <span
      *ngIf="prefix"
      class="
        block
        w-7
        h-7
        text-center text-sm
        leading-0
        absolute
        top-2
        left-2
        text-gray-400
        focus:outline-none
        transition-colors
      "
      >{{ prefix }}</span
    >
    <fa-icon
      *ngIf="icon"
      size="xs"
      class="
        block
        w-7
        h-7
        text-center text-xl
        leading-0
        absolute
        top-1
        right-2
        text-gray-400
        focus:outline-none
        transition-colors
      "
      [ngClass]="clickableIconClass()"
      (click)="onIconClick($event)"
      [icon]="icon"
    ></fa-icon>
    <input
      class="
      {{ icon && 'pr-10' }}
      {{ prefix && 'pl-10' }}
      placeholder-gray-300
      border-gray-300
      appearance-none
      border
      rounded
      w-full
      h-9
      px-3
      text-gray-700
      leading-tight
      {{ classes }}
    "
      [id]="id"
      [type]="type"
      [disabled]="disabled"
      [attr.disabled]="isDisabled ? true : null"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      (ngModelChange)="onInputChange($event)"
    />
  </div>
</div>
