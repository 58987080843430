<app-input
  [id]="id"
  [placeholder]="placeholder === '' ? ('placeholders.type-to-search' | translate) : placeholder"
  [isIconClickable]="true"
  [isIconDisabled]="!isValid"
  [icon]="faSearch"
  (keyup.enter)="onSearch()"
  (inputChange)="onInputChange($event)"
  (iconClick)="onSearch()"
></app-input>
