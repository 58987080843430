<h5 class="font-bold text-secondary mb-2">
  {{ "main-page.services.instructions.introduction" | translate }}
</h5>
<ul class="list-disc list-inside">
  <li>{{ "main-page.services.instructions.time-of-interest" | translate }}</li>
  <li>{{ "main-page.services.instructions.area-of-interest" | translate }}</li>
  <li>{{ "main-page.services.instructions.additional-parameters" | translate }}</li>
  <li>{{ "main-page.services.instructions.press-order" | translate }}</li>
  <li>{{ "main-page.services.instructions.check-results" | translate }}</li>
</ul>
