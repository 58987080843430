<div class="flex flex-col h-full justify-between" [ngClass]="collapsedClass()">
  <div class="flex flex-col">
    <div *ngFor="let menu of menus; index as idx">
      <app-sidebar-button
        *ngxPermissionsOnly="menu.permissionOnly"
        [icon]="menu.icon"
        [disabled]="menu.isDisabled"
        (clicked)="menu.isSelected ? toggleVisibility(idx) : onMenuChange(idx)"
        [isSelected]="menu.isSelected && isMenuVisible[idx]"
        [label]="isCollapsed ? (menu.label | translate) : undefined"
      ></app-sidebar-button>
      
      <app-menu-services
        *ngIf="isSelectedMenu(menu, 'services')"
        [isSidebarCollapsed]="isCollapsed"
        [isVisible]="isMenuVisible[0]"
      ></app-menu-services>

      <app-map-tools
        *ngIf="isSelectedMenu(menu, 'tools')"
        [isSidebarCollapsed]="isCollapsed"
        [isVisible]="isMenuVisible[1]"
      ></app-map-tools>
      <!-- <ng-template ngxPermissionsOnly="customer">
        <app-menu-orders
          *ngIf="isSelectedMenu(menu, 'orders')"
          [isSidebarCollapsed]="isCollapsed"
        >
        </app-menu-orders>
      </ng-template> -->
    </div>
  </div>
  <div>
    <app-sidebar-button
      [icon]="collapseIcon"
      (clicked)="onCollapse()"
      [label]="
        isCollapsed ? ('main-page.sidebar.collapse' | translate) : undefined
      "
    ></app-sidebar-button>
  </div>
</div>
