<button
  class="{{
    isSecondary
      ? 'bg-gradient-to-r from-secondary to-secondary-600'
      : isSuccess
      ? '!bg-gradient-to-r from-green to-green-600'
      : isDangerous
      ? '!bg-gradient-to-r from-danger to-danger-600'
      : isWhite
      ? '!bg-gradient-to-r from-gray-50 to-gray-100 !text-secondary border-gray-100 border '
      : isTransparent
      ? '!text-gray-500 hover:!text-secondary hover:!shadow-none'
      : 'bg-gradient-to-r from-primary to-primary-600'
  }}
  {{
    isDisabled
      ? 'opacity-50 cursor-not-allowed'
      : 'ease-in-out motion-safe:hover:scale-105 motion-safe:active:scale-100 hover:shadow-xl'
  }}
  {{ isFull ? 'w-full justify-center' : '' }}
   flex px-4 py-2 rounded text-white transform duration-300 gap-4
    "
  [disabled]="isDisabled"
  [type]="type"
>
  <fa-icon *ngIf="leftIcon" [icon]="leftIcon" [ngClass]="iconClass"></fa-icon>
  <span *ngIf="!noLabel">{{ label }}</span>
  <fa-icon *ngIf="rightIcon" [icon]="rightIcon" [ngClass]="iconClass"></fa-icon>
</button>
