import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DrawingLayer, DRAWING_COMMIT, Layer, WMSParams, FeatureInfoQueryParameters, FeatureInfoData, FeatureInfoResponse, SnackbarService, TranslateService, TimelineService } from '@core';
import 'leaflet';
import * as L from 'leaflet';
import { CRS } from 'leaflet';
import 'leaflet-draw';
import 'leaflet-editable';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { omitKeys, serializeParams, toDms, toWkt } from 'shared/helpers';
import { xml2json } from 'xml-js';
import * as turf from '@turf/turf';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private map?: L.Map;
  private basemap!: L.TileLayer;
  public drawingLayer$: Subject<DrawingLayer>;
  private tileLayerWMS = (wmsParams: WMSParams, bounds?: L.LatLngBounds) => {
    const wmsParameters = omitKeys(wmsParams, ['bbox', 'styles']);

    return {
      ...wmsParameters,
      styles: wmsParams.styles ? wmsParams.styles.split(',')[0] : '',
      transparent: true,
      bounds,
      crs: CRS.EPSG4326, 
      maxZoom: 22,
      zIndex: 200
    };
  };
  public scale?: number;
  public cursorLatLng?: string;
  private geoLayers!: Map<string, L.TileLayer[]>;
  private geoLegends!: Map<string, L.Control[]>;
  private geoWMSTypes!: Map<string, string>;
  private geoTimestamps!: Map<string, string>;
  private geoTimesteps!: Map<string, string[]>;
  private geoColorShapes!: Map<string, L.GeoJSON>;
  private ncwmsLegendData!: Map<string, [string | undefined, number[] | undefined, string | undefined, boolean]>;

  private geoAnimatedLayerSources!: Map<string, L.TileLayer[]>;
  private geoAnimatedLayers!: Map<string, L.ImageOverlay>;
  public animationLoading = false;

  private uploadedLayers: Layer[] = [];

  private markerIcon = L.icon({
    iconUrl: 'assets/img/marker.svg',
    iconSize: [38, 95], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
  });

  private lineOptions: L.PolylineOptions = {
    color: '#ff0000',
    lineJoin: 'round'
  };

  private markerOptions: L.MarkerOptions = {
    icon: this.markerIcon
  };

  namesOfBaseMaps = {
    'Google Maps': L.tileLayer(
      'https://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      {
        id: 'googleMaps'
      }
    ),
    'Google Terrain Hybrid': L.tileLayer(
      'https://mt.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
      {
        id: 'googleTerrainHybrid'
      }
    ),
    'Google Satellite': L.tileLayer(
      'https://mt.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
      {
        id: 'googleSatellite'
      }
    ),
    'Google Satellite Hybrid': L.tileLayer(
      'https://mt.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
      {
        id: 'googleSatelliteHybrid'
      }
    ),
    'Wikimedia Map': L.tileLayer(
      'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
      {
        id: 'wikimediaMap',
        attribution: 'OpenStreetMap contributors, under ODbL'
      }
    ),
    'Esri Ocean': L.tileLayer(
      'https://services.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}',
      {
        id: 'EsriOcean',
        attribution: ''
      }
    ),
    'Esri Satellite': L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      {
        id: 'EsriSatellite',
        attribution: ''
      }
    ),
    'Esri Topo World': L.tileLayer(
      'http://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
      {
        id: 'EsriTopoWorld',
        attribution: ''
      }
    ),
    'OpenStreetMap Standard': L.tileLayer(
      'http://tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        id: 'OpenStreetMapStandard',
        attribution: 'OpenStreetMap contributors, CC-BY-SA'
      }
    )
  };
  public getPoint: boolean = false;
  public getTimeSeriesPlot: boolean = false;

  private pointMarker: any;
  private identifyMarker: any;
  private timeseriesPlotMarker?: L.Marker;
  private polygonArea: any;
  private pointArea?: L.Marker;
  private markerArea?: L.Marker;
  private rectangleArea?: L.Rectangle;
  public isDrawing: boolean = false;
  private populatedLayer?: L.GeoJSON;
  private footprintLayer?: L.GeoJSON;
  private footprintArea?: L.Polygon;
  private lineArea: any;

  private drawContext!: string;

  private layerOnTop?: string;
  private timelineOnTop?: string;

  private animationOn = false;

  private chartStartDate?: Date;
  private chartEndDate?: Date;

  private lastQueryParameters?: FeatureInfoQueryParameters;

  private featureInfoCompleted: Subject<FeatureInfoResponse> = new Subject();
  public featureInfoCompleted$ = this.featureInfoCompleted.asObservable();

  private timeseriesCompleted: Subject<[L.TileLayer[], [Date, number][]][]> = new Subject();
  public timeseriesCompleted$ = this.timeseriesCompleted.asObservable();

  private ncwmsLegendDataComplete: Subject<[string | undefined, number[] | undefined, string | undefined, boolean]> = new Subject();
  public ncwmsLegendDataComplete$ = this.ncwmsLegendDataComplete.asObservable();

  private topLayerChanged: Subject<string> = new Subject();
  public topLayerChanged$ = this.topLayerChanged.asObservable();

  private styledLayer?: Layer;
  private styledLayerId?: string;

  private loadedOrders: number[] = [];

  constructor(private http: HttpClient,
    private snackbar: SnackbarService,
    private translateService: TranslateService) {
      this.drawingLayer$ = new Subject<DrawingLayer>();
      this.basemap?.addTo(this.map as L.Map);
      this.geoLayers = new Map();
      this.geoLegends = new Map();
      this.geoWMSTypes = new Map();
      this.geoTimestamps = new Map();
      this.geoAnimatedLayerSources = new Map();
      this.geoAnimatedLayers = new Map();
      this.geoTimesteps = new Map();
      this.geoColorShapes = new Map();
      this.ncwmsLegendData = new Map();
    }

  private setScale() {
    const getScale = () => {
      const screenDpi = 96;
      const inchPerDecimalDegree = 4374754;
      const bounds = (this.map as L.Map).getBounds();
      const width = bounds.getEast() - bounds.getWest();

      const screenWidth = (this.map as L.Map).getSize().x;

      return (width * inchPerDecimalDegree * screenDpi) / screenWidth;
    };

    this.map?.on('move', () => {
      this.scale = getScale();
    });

    this.scale = getScale();
  }

  public initializeMap(
    divId: string,
    center: [number, number],
    initialZoom: number
  ): void {
    const southWest = L.latLng(-89.98155760646617, -180),
      northEast = L.latLng(89.99346179538875, 180);
    const bounds = L.latLngBounds(southWest, northEast);
    this.map = L.map(divId, {
      editable: true,
      maxBounds: bounds,
      attributionControl: false,
      minZoom: 3,
      zoomControl: false,
      maxBoundsViscosity: 1.0
    });
    this.map?.setView(center, initialZoom);
    L.control.layers(this.namesOfBaseMaps, undefined, {position: 'bottomright'}).addTo(this.map);
    this.map?.on('click', (event: any) => {
      if (this.getPoint) {
        if (this.pointMarker !== undefined) {
          this.removePointMarker();
        }
        this.getPoint = false;
        const coords = L.latLng(event.latlng.lat, event.latlng.lng);
        const point = this.map?.latLngToContainerPoint(coords);
        this.pointMarker = L.marker([coords.lat, coords.lng], {
          icon: this.markerIcon
        }).addTo(this.map as L.Map);
      }
      if (this.getTimeSeriesPlot) {
        if (this.timeseriesPlotMarker) {
          this.removeTimeseriesPlotMarker();
        }
        this.getTimeSeries(event);
      }
      if (!this.getPoint && !this.isDrawing && !this.getTimeSeriesPlot) {
        if (this.identifyMarker !== undefined) {
          this.removeIdentifyMarker();
        }
        this.identify(event);
      }
    });

    this.setScale();

    this.map?.on('mousemove', (event: L.LeafletMouseEvent) => {
      const { lat, lng } = event.latlng;

      this.cursorLatLng = `${toDms(lat, lng).lat}, ${toDms(lat, lng).lng}`;
    });

    this.map?.on('editable:vertex:dragend', event =>
      this.observeDrawingLayer(event.layer, event.type)
    );

    this.map?.on('editable:vertex:deleted', event =>
      this.observeDrawingLayer(event.layer, event.type)
    );

    this.map?.on('editable:dragend', event =>
      this.observeDrawingLayer(event.layer, event.type)
    );

    this.map?.on('editable:drag', event =>
      this.observeDrawingLayer(event.layer, event.type)
    );

    this.map?.on('editable:drawing:start', () => (this.isDrawing = true));
    this.map?.on('editable:drawing:end', () => (this.isDrawing = false));

  }

  private observeDrawingLayer(
    layer: L.Polygon | L.Polyline | L.Marker,
    type: string
  ) {
    const wkt = toWkt(layer);
    this.map?.removeEventListener(DRAWING_COMMIT);
    if (wkt) this.drawingLayer$.next({ wkt, layer, type });
  }

  public async populateMap(geoJSON: GeoJSON.GeoJSON, color?: string) {
    this.clearMap();
    let lineOptions: L.PolylineOptions = {
      color: color ?? '#54b200',
      lineJoin: 'round'
    };
    if (geoJSON && this.map) {
      this.populatedLayer = L.geoJSON(undefined, lineOptions).addTo(
        this.map as L.Map
      );
      this.populatedLayer?.addData(geoJSON);
      const bounds = this.populatedLayer?.getBounds();
      if (bounds) {
        this.map?.fitBounds(bounds);
      }
      this.populatedLayer?.getLayers().map(layer => {
        switch (geoJSON.type) {
          case 'Polygon':
          case 'MultiPolygon':
            this.polygonArea = layer as L.Polygon;
            break;
          case 'LineString':
            this.lineArea = layer as L.Polyline;
            break;
          case 'Point':
            (layer as L.Marker).setIcon(this.markerIcon)
            this.pointArea = layer as L.Marker;
            break;
        }
      });
    }
  }

  public setBaseMap(basemapUrl: string, attribution: string): void {
    L.tileLayer(basemapUrl, { attribution }).addTo(this.map as L.Map);
  }

  public replaceBasemap(basemap: L.TileLayer): void {
    if (this.basemap) this.map?.removeLayer(this.basemap);

    basemap.addTo(this.map as L.Map);
    this.basemap = basemap;
  }

  public removePointMarker() {
    if (this.pointMarker !== undefined) {
      this.map?.removeLayer(this.pointMarker);
      this.pointMarker = undefined;
    }
  }

  public removeIdentifyMarker() {
    if (this.identifyMarker) {
      this.map?.removeLayer(this.identifyMarker);
      this.identifyMarker = undefined;
    }
  }

  public removeTimeseriesPlotMarker() {
    if (this.timeseriesPlotMarker) {
      this.map?.removeLayer(this.timeseriesPlotMarker);
      this.timeseriesPlotMarker = undefined;
    }
  }

  public get timeseriesMarkerLatLngs() {
    return this.timeseriesPlotMarker?.getLatLng();
  }

  private pinMarker(markerOptions: L.MarkerOptions) {
    this.map?.addEventListener(DRAWING_COMMIT, event => {
      const layer = event.layer;
      // layer.disableEdit();
      this.markerArea = layer;
      
      this.observeDrawingLayer(layer, event.type);
    });
    this.map?.editTools.startMarker(undefined, markerOptions);
  }

  private drawPolygon(lineOptions: L.PolylineOptions): void {
    this.map?.addEventListener(DRAWING_COMMIT, event => {
      const layer = event.layer;
      // layer.disableEdit();
      this.polygonArea = layer;
      
      this.observeDrawingLayer(layer, event.type);
    });

    this.map?.editTools.startPolygon(undefined, lineOptions);
  }

  private drawLine(lineOptions: L.PolylineOptions): void {
    this.map?.addEventListener(DRAWING_COMMIT, event => {
      const layer = event.layer;
      // layer.disableEdit();
      this.lineArea = layer;
      
      this.observeDrawingLayer(layer, event.type);
    });

    this.map?.editTools.startPolyline(undefined, lineOptions);
  }

  private drawRectangle(lineOptions: L.PolylineOptions): void {
    this.map?.addEventListener(DRAWING_COMMIT, (event: any) => {
      const layer: L.Rectangle = event.layer;
      this.rectangleArea = layer;
    });
    this.map?.editTools.startRectangle(undefined, lineOptions);
  }

  public destroyMap() {
    if (this.map !== undefined) {
      this.map.remove();
      this.map = undefined;
      this.drawingLayer$.next(undefined);
    }
  }

  public drawOnMap(typeOfDraw: string, context: string) {
    this.switchDrawContext(context);
    this.clearMap();
    switch (typeOfDraw) {
      case 'polygon':
        this.drawPolygon(this.lineOptions);
        break;
      case 'line':
        this.drawLine(this.lineOptions);
        break;
      case 'marker':
        this.pinMarker(this.markerOptions);
        break;
      case 'rectangle':
        this.drawRectangle(this.lineOptions);
        break;
    }
  }

  get geoJSON() {
    if (this.polygonArea !== undefined) {
      return this.polygonArea.toGeoJSON();
    }
    if (this.pointMarker !== undefined) {
      return this.pointMarker.toGeoJSON();
    }
    if (this.lineArea !== undefined) {
      return this.lineArea.toGeoJSON();
    }
    return null;
  }

  get geoWkt() {
    if (this.polygonArea !== undefined) {
      return toWkt(this.polygonArea);
    }
    if (this.markerArea !== undefined) {
      return toWkt(this.markerArea);
    }
    if (this.lineArea !== undefined) {
      return toWkt(this.lineArea);
    }
    return null;
  }

  public async setFootprint(geoJSON: GeoJSON.GeoJSON) {
    let lineOptions: L.PolylineOptions = {
      color: '#54b200',
      lineJoin: 'round',
      fillRule: 'nonzero'
    };
    if (geoJSON && this.map) {
      this.footprintLayer = L.geoJSON(undefined, lineOptions).addTo(
        this.map as L.Map
      );
      this.footprintLayer?.addData(geoJSON);
      if (this.footprintLayer) {
        const bounds = this.footprintLayer?.getBounds();
        this.map?.fitBounds(bounds);
      }

      this.footprintLayer?.getLayers().map(layer => {
        this.footprintArea = layer as L.Polygon;
      });
    }
  }

  public clearMap(): void {
    if (this.polygonArea) {
      this.map?.removeLayer(this.polygonArea);
      this.polygonArea = undefined;
    }
    if (this.markerArea) {
      this.map?.removeLayer(this.markerArea);
      this.markerArea = undefined;
    }
    if (this.lineArea) {
      this.map?.removeLayer(this.lineArea);
      this.lineArea = undefined;
    }
    if (this.rectangleArea) {
      this.map?.removeLayer(this.rectangleArea);
      this.rectangleArea = undefined;
    }
  }

  public clearFootprint(): void {
    if (this.footprintArea) {
      this.map?.removeLayer(this.footprintArea);
      this.footprintArea = undefined;
    }
  }

  public destroyDrawingLayer() {
    this.drawingLayer$.next();
  }

  public addGeoTiff(layerId: string, layer: Layer, multiSelect?: boolean, wfsGeoJson?: any, shapeColor?: string) {
    let { geoServerURI, wmsParams } = layer;
    let bounds;

    if (wmsParams && wmsParams.bbox) {
      const minY = Number(wmsParams.bbox.split(',')[0]);
      const minX = Number(wmsParams.bbox.split(',')[1]);
      const maxY = Number(wmsParams.bbox.split(',')[2]);
      const maxX = Number(wmsParams.bbox.split(',')[3]);

      const min = L.latLng(minY, minX);
      const max = L.latLng(maxY, maxX);
      bounds = L.latLngBounds(min, max);
    }

    if (wfsGeoJson && shapeColor) {
      const geoJson = L.geoJSON(wfsGeoJson, {
        style: (feature) => {
          return {color: '#'+shapeColor, fillRule: 'nonzero'}
        }
      }).addTo(this.map as L.Map);
      this.geoColorShapes.set(layerId, geoJson);
    }

    if (geoServerURI.split('/').includes('metoc')) {
      geoServerURI = geoServerURI.replace('/metoc', `/${wmsParams.layers.split('/')[0]}`)
    }

    const leafletSources: L.TileLayer[] = [
      this.addTileLayerWMS(
        geoServerURI,
        this.tileLayerWMS(wmsParams, bounds)
      )
    ];

    if(!wfsGeoJson) {
      leafletSources.forEach((s) => s.addTo(this.map as L.Map));
    }

    let addLegend = true;
    if (multiSelect) {
      const ids = Array.from(this.geoLegends.keys());
      ids.forEach((id) => {
        const key = `${layer.layerKey}!${layer.orderID}`
        if (id.includes(key)) {
          addLegend = false;
          return
        }
      });
    }

    if (addLegend) {
      if (!layer.dataDissemination || this.isExternalGeoserver(layer)) {
        const leafletLegends: L.Control[] = [
          this.addLegendToMap(geoServerURI, wmsParams)
        ];
        this.geoLegends.set(layerId, leafletLegends);
      } else {
        const dataURL = geoServerURI.toString().split('?', 1);
        const newDataURL = new URL(dataURL[0]);
        newDataURL.searchParams.append('request', 'GetMetadata');
        newDataURL.searchParams.append('item', 'layerDetails');
        newDataURL.searchParams.append('layerName', `${wmsParams.layers}`);

        let scaleRange;
        let scaleUnit;
        let palette;

        this.getLayerLegend(newDataURL.toString()).pipe(take(1)).subscribe(result => {

          scaleRange = this.generateLinearScaleArray(result.scaleRange);
  
          if (result.units !== undefined) {
            scaleUnit = result.units;
          }
          else {
            scaleUnit = '';
          }
         
          palette = result.palettes.find((pal: string) => pal === wmsParams.styles?.toUpperCase())

          const params = serializeParams({
            layers: wmsParams.layers,
            layer: wmsParams.layers,
            style: wmsParams.styles ? wmsParams.styles.split(',')[0] : '',
            version: wmsParams.version ?? '1.1.1',
            width: 30
            //height: wmsParams.styles ? '30' : '255'
          });
          const legendUrl = `${geoServerURI}?service=WMS&request=GetLegendGraphic&FORMAT=image/png&${params}`;
          const colobarURL = this.getLegendUrl(legendUrl, wmsParams.styles, palette);

          this.ncwmsLegendDataComplete.next([colobarURL, scaleRange, scaleUnit, true]);
          this.ncwmsLegendData.set(layerId, [colobarURL, scaleRange, scaleUnit, true]);
  
        }, (err) => {
          console.error(err);
          
        },
          () => {});
      }
      
    }


    const wmsType = layer.wmsType ? layer.wmsType : '';
    this.geoLayers.set(layerId, leafletSources);
    this.geoWMSTypes.set(layerId, wmsType);
    this.geoTimestamps.set(layerId, layer.wmsParams.time);
    this.geoTimesteps.set(layerId, layer.timesteps);

    if (!this.loadedOrders.find((orderID) => layer.orderID === orderID)) {
      if (bounds) this.map?.fitBounds(bounds);
      this.loadedOrders.push(layer.orderID);
    }

    return leafletSources;
  }

  private addTileLayerWMS(baseUrl: string, options: L.WMSOptions) {
    return L.tileLayer.wms(baseUrl, options);
  }

  private addLegendToMap(baseUrl: string, wmsParams: WMSParams) {
    const params = serializeParams({
      layers: wmsParams.layers,
      layer: wmsParams.layers,
      style: wmsParams.styles ? (wmsParams.styles.split(',').length > 1 ? wmsParams.styles.split(',')[0] : wmsParams.styles) : '',
      version: wmsParams.version ?? '1.1.1',
      width: 70,
      height: 180
      //height: wmsParams.styles ? '30' : '255'
    });
    const legendUrl = `${baseUrl}?service=WMS&request=GetLegendGraphic&FORMAT=image/png&colorbaronly=false&vertical=true&${params}`;
    const control = new L.Control({ position: 'bottomright' });

    control.onAdd = () => {
      let div = L.DomUtil.create('img');

      div.setAttribute('src', legendUrl);
      div.setAttribute('alt', '');
      div.style.borderRadius = '0.3rem';
      div.style.opacity = '90%';
      div.style.maxHeight = '38rem';
      div.style.maxWidth = '10rem';

      return div;
    };

    return control.addTo(this.map as L.Map);
  }

  public destroyLayer(layerId: string) {
    const layers = this.geoLayers?.get(layerId);
    layers?.forEach(layer => this.map?.removeLayer(layer));

    const legends = this.geoLegends?.get(layerId);
    const colorShape = this.geoColorShapes?.get(layerId);
    if (colorShape) this.map?.removeLayer(colorShape);

    legends?.forEach(legend => this.map?.removeControl(legend));

    this.geoLayers.delete(layerId);
    this.geoLegends.delete(layerId);
    this.geoTimestamps.delete(layerId);
    this.geoWMSTypes.delete(layerId);
    this.geoTimesteps.delete(layerId);
    this.geoColorShapes.delete(layerId);

    if (this.ncwmsLegendData.get(layerId)) {
      this.ncwmsLegendData.delete(layerId);
      if (Array.from(this.ncwmsLegendData.keys()).length > 0) {
        this.ncwmsLegendDataComplete.next(Array.from(this.ncwmsLegendData.values())[0]);
      } else {
        this.ncwmsLegendDataComplete.next([undefined, undefined, undefined, false]);
      }
    }

    if (layerId === this.layerOnTop) {
      this.layerOnTop = undefined;
    }

    this.featureInfoCompleted.next(undefined);
    this.removeIdentifyMarker();
  }

  public destroyAllLayers() {
    const layers = this.geoLayers.values();
    const legends = this.geoLegends.values();
    const colorShapes = this.geoColorShapes.values();

    const layerArrays = Array.from(layers);
    const legendArrays = Array.from(legends);
    const colorShapeArray = Array.from(colorShapes);

    const eachLayer: L.TileLayer[] = [];
    const eachLegend: L.Control[] = [];

    layerArrays.forEach(layerArray =>
      layerArray.forEach(layer => eachLayer.push(layer))
    );

    legendArrays.forEach(legendArray =>
      legendArray.forEach(legend => eachLegend.push(legend))
    );

    colorShapeArray.forEach((colorShape) => this.map?.removeLayer(colorShape));

    eachLayer?.forEach(layer => this.map?.removeLayer(layer));
    eachLegend?.forEach(legend => this.map?.removeControl(legend));

    this.geoLayers.clear();
    this.geoLegends.clear();
    this.geoTimestamps.clear();
    this.geoWMSTypes.clear();
    this.geoTimesteps.clear();
    this.geoColorShapes.clear();

    this.layerOnTop = undefined;

    this.loadedOrders = [];

    this.featureInfoCompleted.next(undefined);
    this.removeIdentifyMarker();
  }

  private identify(mapEvent: any) {
    if (this.map && this.map.options.crs) {
      const coords = L.latLng(mapEvent.latlng.lat, mapEvent.latlng.lng);
      const point = this.map?.latLngToContainerPoint(coords);
      const queryParams: FeatureInfoQueryParameters = {
        latitude: toDms(coords.lat, coords.lng).lat,
        longitude: toDms(coords.lat, coords.lng).lng,
        bbox: this.bbox(),
        width: this.map ? this.map.getSize().x : 0,
        height: this.map ? this.map.getSize().y : 0,
        x: point ? point.x : 0,
        y: point ? point.y : 0,
        crs: this.map.options.crs
      }
      this.lastQueryParameters = queryParams;
      this.identifyMarker = L.marker([coords.lat, coords.lng], {
        icon: this.markerIcon
      }).addTo(this.map as L.Map);
      this.submitFeatureInfoRequest(queryParams);
    }

  }

  public get mapCRS() {
    return this.map?.options.crs;
  }

  private bbox() {
    const crs = this.map?.options.crs;
    const bounds = this.map?.getBounds();
    if (bounds && crs) {
      const nw = crs.project(bounds.getNorthWest());
      const se = crs.project(bounds.getSouthEast());
      return [nw.x, se.y, se.x, nw.y].join(',');
    } else {
      return '';
    }

  }

  private getFeatureInfoParams(layerName: string, queryParameters: FeatureInfoQueryParameters) {
    return {
      request: 'GetFeatureInfo',
      service: 'WMS',
      version: '1.1.1',
      info_format: 'text/xml',
      feature_count: 100,
      layers: layerName,
      query_layers: layerName,
      srs: queryParameters.crs.code,
      bbox: queryParameters.bbox,
      width: queryParameters.width,
      height: queryParameters.height,
      x: Math.trunc(queryParameters.x),
      y: Math.trunc(queryParameters.y)
    }
  }

  private parseFeatureInfoResponse(layerName: string, timestamp: string | undefined, response: any): FeatureInfoData {
    const featureInfoData: FeatureInfoData = {
      layerName: '',
      featureData: [],
      time: timestamp
    }
    try {
      const parsedResponse = JSON.parse(xml2json(response, {compact: true}));
      const geoserverKey = Object.keys(parsedResponse).find((key) => key.includes('FeatureCollection'));
      const ncwmsKey = Object.keys(parsedResponse).find((key) => key.includes('FeatureInfoResponse'));

      if (geoserverKey) {
        // parse Geoserver response
        const featureCollection = parsedResponse[geoserverKey];
        const featureMemberKey = Object.keys(featureCollection).find((key) => key.includes('featureMember'));
        if (featureMemberKey) {
          featureInfoData.layerName = layerName;
          let featureMemberList = featureCollection[featureMemberKey];
          if (!this.isIterable(featureMemberList)) {
            featureMemberList = [featureMemberList];
          }
          for (const featureMember of featureMemberList) {
            const featureData: Map<string, string | number> = new Map();
            const features = featureMember[Object.keys(featureMember)[0]]
            const featureKeys = Object.keys(features).filter((key) => !key.includes('_attributes'));
            featureKeys.forEach((featureKey) => {
              const featureName = featureKey.split(':')[featureKey.split(':').length - 1];
              featureData.set(featureName, features[featureKey]['_text']);
            });
            featureInfoData.featureData.push(featureData);
          }
        }
      } else if (ncwmsKey) {
        // parse NCWMS
        const featureData: Map<string, string | number> = new Map();
        if (parsedResponse['FeatureInfoResponse']['Feature']) {
          featureInfoData.layerName = layerName;
          const value = parsedResponse['FeatureInfoResponse']['Feature']['FeatureInfo']['value']['_text'];
          featureInfoData.featureData.push(featureData.set('Value', value));
        }

      }
      } catch (error) {
        console.error(error)
    }


    return featureInfoData;
  }

  public submitFeatureInfoRequest(queryParameters: FeatureInfoQueryParameters) {
    const layerIDs = Array.from(this.geoLayers.keys());
    const featureInfoResponse: FeatureInfoResponse = {
      latitude: queryParameters.latitude,
      longitude: queryParameters.longitude,
      featureInfoData: []
    }
    const requests: {[x: string]: Observable<Object>} = {};
    layerIDs.map((id) => {
      const sources = this.geoLayers.get(id);
      if (sources) {
        const wmsURL = (sources[0] as any)._url;
        const layerName: string = (sources[0] as any).options.layers;
        const parameters = this.getFeatureInfoParams(layerName, queryParameters);
        let requestURL = wmsURL + L.Util.getParamString(parameters, wmsURL);

        const timestamp = this.geoTimestamps.get(id);
        if (timestamp !== '') {
          requestURL += '&time=' + timestamp;
        }
        requests[`${id}`] = this.http.get(requestURL, { responseType: 'text' }).pipe(catchError(error => of(error)))
      }

    });

    forkJoin(requests).pipe(take(1)).subscribe((result) => {
      const resultLayers = Object.keys(result);
      resultLayers.map((layerId) => {
        const sources = this.geoLayers.get(layerId);
        const layerName = sources? (sources[0] as any).options.layers : '';
        const timestamp = this.geoTimestamps.get(layerId);
        const parsedResponse = this.parseFeatureInfoResponse(layerName, timestamp, result[layerId]);
        if (parsedResponse.layerName !== '') {
          featureInfoResponse.featureInfoData.push(parsedResponse);
        }

      });
    },
    (error) => console.error(error),
    () => {
      this.featureInfoCompleted.next(featureInfoResponse);
    });

  }

  isIterable(object: any) {
    if (!object) {
      return false;
    }
    return typeof object[Symbol.iterator] === 'function';
  }


  calculateLineDistance() {
    if (this.lineArea && this.map) {
      let distance = 0;
      const coordinates: L.LatLng[] = this.lineArea._latlngs;
      for (let idx = 1; idx < coordinates.length; idx++) {
        distance += this.map.distance(coordinates[idx-1], coordinates[idx]);
      }
      return distance;
    } else {
      return 0;
    }
  }

  calculateArea() {
    if (this.polygonArea && this.map) {
      const geoJson = this.polygonArea.toGeoJSON();
      return turf.area(geoJson);
    } else {
      return 0;
    }
  }

  private getTimeseriesRequestParams(layerName: string,
    startDate: Date, endDate: Date, queryParameters: FeatureInfoQueryParameters) {
    return {
      request: 'GetTimeseries',
      service: 'WMS',
      version: '1.1.1',
      info_format: 'text/csv',
      format: 'image/png',
      layers: layerName,
      query_layers: layerName,
      srs: queryParameters.crs.code,
      bbox: queryParameters.bbox,
      width: queryParameters.width,
      height: queryParameters.height,
      x: Math.trunc(queryParameters.x),
      y: Math.trunc(queryParameters.y),
      bgcolor: 'transparent',
      uppercase: true,
      abovemaxcolor: 'transparent',
      belowmincolor: 'transparent',
      transparent: true,
      time: `${startDate?.toISOString()}/${endDate?.toISOString()}`
    }
  }

  private submitTimeseriesRequest(queryParameters: FeatureInfoQueryParameters, startDate?: Date, endDate?: Date) {
    const layerIDs = Array.from(this.geoLayers.keys());
    const requests: {[x: string]: Observable<Object>} = {};

    layerIDs.map((id) => {
      const sources = this.geoLayers.get(id);
      const timesteps = this.geoTimesteps.get(id);
      if (sources && timesteps) {
        const wmsURL = (sources[0] as any)._url;
        const layerName: string = (sources[0] as any).options.layers;
        
        if (!startDate) {
          startDate = new Date(timesteps[0]);
        }
        if (!endDate) {
          const selectedTime = this.geoTimestamps.get(id);
          if (selectedTime) {
            endDate = new Date(selectedTime);
            if (endDate.getTime() === startDate.getTime()) {
              endDate = new Date(timesteps[timesteps.length - 1]);
            }
          } else {
            endDate = new Date(timesteps[timesteps.length - 1]);
          }
        }
        this.chartStartDate = startDate;
        this.chartEndDate = endDate;
        const parameters = this.getTimeseriesRequestParams(layerName, startDate, endDate, queryParameters);
        let requestURL = wmsURL + L.Util.getParamString(parameters, wmsURL);

        requests[`${id}`] = this.http.get(requestURL, { responseType: 'text' }).pipe(catchError(error => of(error)))
      }

    });

    forkJoin(requests).pipe(take(1)).subscribe((result) => {
      const resultLayers = Object.keys(result);
      const timeseriesList: [L.TileLayer[], [Date, number][]][] = [];
      resultLayers.forEach((layerId) => {
        try {
          const sources = this.geoLayers.get(layerId);
          const table: string[][] = this.parseCsv(result[layerId]);
          const series: [Date, number][] = table.slice(1).map((row) => {
            const date: Date = new Date(Date.parse(row[0]));
            const value: number = Math.round(parseFloat(row[1]) * 100) / 100;
            const result: [Date, number] = [date, value];
            return result;
          });
          if (sources) {
            this.validateTimeSeries(series);
            timeseriesList.push([sources, series]);
          }
        } catch (error) {
          console.error(error);
        }
        
      });
      this.timeseriesCompleted.next(timeseriesList);
    },
    (error) => {
      console.error(error);
    },
    () => {
    });
  }

  private getTimeSeries(mapEvent: any) {
    if (this.map && this.map.options.crs) {
      const coords = L.latLng(mapEvent.latlng.lat, mapEvent.latlng.lng);
      const point = this.map?.latLngToContainerPoint(coords);
      const queryParams: FeatureInfoQueryParameters = {
        latitude: toDms(coords.lat, coords.lng).lat,
        longitude: toDms(coords.lat, coords.lng).lng,
        bbox: this.bbox(),
        width: this.map ? this.map.getSize().x : 0,
        height: this.map ? this.map.getSize().y : 0,
        x: point ? point.x : 0,
        y: point ? point.y : 0,
        crs: this.map.options.crs
      }
      this.lastQueryParameters = queryParams;
      this.timeseriesPlotMarker = L.marker([coords.lat, coords.lng], {
        icon: this.markerIcon
      }).addTo(this.map as L.Map);
      if (this.chartStartDate && this.chartEndDate) {
        this.submitTimeseriesRequest(queryParams, this.chartStartDate, this.chartEndDate)
      } else {
        this.submitTimeseriesRequest(queryParams);
      }
    }
  }

  private parseCsv(csv: string): string[][] {
    return csv.trim()
      .split(/\r?\n/)
      .filter((row) => {
        return !row.startsWith('#');
      })
      .map((row) => {
        return row.split(',');
      });
  }

  private validateTimeSeries(series: [Date, number][]) {
    let invalidSeriesIndexes = [];
    series.forEach(([date, number], index) => {
      if (!(date instanceof Date) || isNaN(number)) {
        invalidSeriesIndexes.push(index);
      }
    });
    for (let i = invalidSeriesIndexes.length - 1; i >= 0; i--) {
      series.splice(i, 1);
    }
  }

  public updateTimeseries(newStartDate: Date, newEndDate: Date) {
    if (this.lastQueryParameters) {
      this.chartStartDate = newStartDate;
      this.chartEndDate = newEndDate;
      this.submitTimeseriesRequest(this.lastQueryParameters, newStartDate, newEndDate);
    }
  }

  changeLayerZIndex(layerSource: L.TileLayer.WMS, zIndex: number) {
    layerSource.setZIndex(zIndex);
  }

  bringLayerToTop(layerSimpleId: string) {
    const layerId = Array.from(this.geoLayers.keys()).find((key) => key.includes(layerSimpleId));
    if (layerId) {
      const targetLayerSource = this.geoLayers.get(layerId);
      const layerIds = Array.from(this.geoLayers.keys());
      if (targetLayerSource && targetLayerSource.length > 0) {
        layerIds.forEach((id) => {
          if (id === layerId) {
            targetLayerSource.forEach((source) => {
              this.changeLayerZIndex(source as L.TileLayer.WMS, 500);
            });
            
            this.layerOnTop = id;
            if (this.ncwmsLegendData.get(layerId)) {
              this.ncwmsLegendDataComplete.next(this.ncwmsLegendData.get(layerId));
            }
            this.topLayerChanged.next(layerSimpleId);
            this.timelineOnTop = layerSimpleId;
          } else {
            this.geoLayers.get(id)?.forEach((source) => {
              this.changeLayerZIndex(source as L.TileLayer.WMS, 100);
            });
          }
        })
      }
    } else{
      this.topLayerChanged.next(layerSimpleId);
      this.timelineOnTop = layerSimpleId;
    }
  }

  get topTimeline(){
    return this.timelineOnTop;
  }

  get topLayer() {
    return this.layerOnTop;
  }

  public toggleLegend(layerSimpleId: string) {
    const layerId = Array.from(this.geoLayers.keys()).find((key) => key.includes(layerSimpleId));
    if (layerId) {
      const legendControl = this.geoLegends.get(layerId);
      if (legendControl) {
        legendControl.forEach(ctrl => {
          const container = ctrl.getContainer();
          if (container) {
            if (container.style.display === 'none') {
              container.style.display = ''
            } else {
              container.style.display = 'none'
            }
          }
        });
      }
      const ncwmsLegendData = this.ncwmsLegendData.get(layerId);
      if (ncwmsLegendData) {
        ncwmsLegendData[3] = !ncwmsLegendData[3];
        this.ncwmsLegendData.set(layerId, ncwmsLegendData);
        this.ncwmsLegendDataComplete.next(ncwmsLegendData);
      }
    }
  }

  public zoomToBoundingBox(bbox: string) {
    const minY = Number(bbox.split(',')[0]);
      const minX = Number(bbox.split(',')[1]);
      const maxY = Number(bbox.split(',')[2]);
      const maxX = Number(bbox.split(',')[3]);

      const min = L.latLng(minY, minX);
      const max = L.latLng(maxY, maxX);
      const bounds = L.latLngBounds(min, max);
      this.map?.fitBounds(bounds);
  }


  setLayerOpacity(layerSimpleId: string, value: number) {
    const layerId = Array.from(this.geoLayers.keys()).find((key) => key.includes(layerSimpleId));
    if (layerId) {
      const layer = this.geoLayers.get(layerId);
      if (layer) {
        layer.forEach((lyr) => {
          lyr.setOpacity(value);
        });
      }
    }
  }

  setLayerStyle(layerSimpleId: string, style: string) {
    const layerId = Array.from(this.geoLayers.keys()).find((key) => key.includes(layerSimpleId));
    if (layerId) {
      const layer = this.geoLayers.get(layerId);
      if (layer) {
        layer.forEach((lyr) => {
          (lyr as any).setParams({'styles': style});
        });
      }
    }
  }

  public addExistingLayerToMap(layerId: string, layer: Layer, leafletSource: any) {
    const { wmsParams } = layer;
    let bounds;

    if (wmsParams.bbox) {
      const minY = Number(wmsParams.bbox.split(',')[0]);
      const minX = Number(wmsParams.bbox.split(',')[1]);
      const maxY = Number(wmsParams.bbox.split(',')[2]);
      const maxX = Number(wmsParams.bbox.split(',')[3]);

      const min = L.latLng(minY, minX);
      const max = L.latLng(maxY, maxX);
      bounds = L.latLngBounds(min, max);
    }

    this.map?.addLayer(leafletSource);

    const wmsType = layer.wmsType ? layer.wmsType : '';
    this.geoLayers.set(layerId, [leafletSource]);
    this.geoWMSTypes.set(layerId, wmsType);

    if (bounds) this.map?.fitBounds(bounds);
  }

  addUploadedLayer(layer: Layer) {
    this.uploadedLayers.push(layer);
  }

  removeUploadedLayer(layer: Layer) {
    const index = this.uploadedLayers.findIndex((lyr) => lyr === layer);
    if (index > -1) {
      this.destroyLayer(layer.layerKey);
      this.uploadedLayers.splice(index, 1);
    }
  }

  get uploadedLayersList() {
    return this.uploadedLayers;
  }

  addAnimatedLayer(layer: Layer, layerId: string, timeInterval: string, frameRate: number) {
    const animatedLayerSources = this.geoLayers.get(layerId);
    if (animatedLayerSources) {
      
      const title = this.translateService.translate('main-page.snackbars.success.animations.loading.title');
      const message = this.translateService.translate('main-page.snackbars.success.animations.loading.message');
      this.snackbar.success(title, message).during(3000).show();

      this.animationLoading = true;
      this.geoAnimatedLayerSources.set(layerId, animatedLayerSources);
      const minY = Number(layer.wmsParams.bbox.split(',')[0]);
      const minX = Number(layer.wmsParams.bbox.split(',')[1]);
      const maxY = Number(layer.wmsParams.bbox.split(',')[2]);
      const maxX = Number(layer.wmsParams.bbox.split(',')[3]);
      const bboxString = [minY, minX, maxY, maxX].join(',')
      const styles = layer.wmsParams.styles ? layer.wmsParams.styles : 'default';
      const params = [
        'service=WMS',
        'request=GetMap',
        'version=1.3.0',
        'format=image/gif',
        'transparent=true',
        'styles=' + styles,
        'layers=' + layer.layerKey,
        'time=' + timeInterval,
        'bbox=' + bboxString,
        'width=1024',
        'height=1024',
        'animation=true',
        'CRS=' + 'EPSG:4326',
        'frameRate=' + frameRate,
        'BGCOLOR=transparent',
      ];
    
      const imgUrl = layer.geoServerURI + '?' + params.join('&');
      const min = L.latLng(minY, minX);
      const max = L.latLng(maxY, maxX);
      const bounds = L.latLngBounds(min, max);
      const animatedLayer = L.imageOverlay(imgUrl, bounds, {
        opacity: 1
      });
      this.animationOn = true;
      animatedLayerSources.forEach((layer) => this.map?.removeLayer(layer));
      this.map?.addLayer(animatedLayer);
      animatedLayer.on('error', () => {
        this.animationOn = false;
        this.animationLoading = false;
        animatedLayer.remove();
        this.map?.removeLayer(animatedLayer);
        this.geoAnimatedLayerSources.get(layerId)?.forEach((layer) => this.map?.addLayer(layer));
        this.geoAnimatedLayerSources.delete(layerId);

        const title = this.translateService.translate('main-page.snackbars.danger.animations.error-loading.title');
        const message = this.translateService.translate('main-page.snackbars.danger.animations.error-loading.message');
        this.snackbar.danger(title, message).during(5000).show();
      });

      animatedLayer.on('load', () => {
        this.animationLoading = false;
        this.geoAnimatedLayers.set(layerId, animatedLayer);
      })
    } else {
      console.error('no layers to be animated')
      const title = this.translateService.translate('main-page.snackbars.danger.animations.layer-error.title');
      const message = this.translateService.translate('main-page.snackbars.danger.animations.layer-error.message');
      this.snackbar.danger(title, message).during(5000).show();
    }
  }

  removeAnimatedLayer(layerId: string) {
    const animatedLayer = this.geoAnimatedLayers.get(layerId);
    this.animationOn = false;
    if (animatedLayer) {
      animatedLayer.remove();
      this.map?.removeLayer(animatedLayer);
      this.geoAnimatedLayerSources.get(layerId)?.forEach((layer) => this.map?.addLayer(layer));
      this.geoAnimatedLayerSources.delete(layerId);
    }
  }

  get isAnimationActive() {
    return this.animationOn;
  }


  switchDrawContext(newContext: string) {
    this.drawContext = newContext;
  }

  get drawingContext() {
    return this.drawContext;
  }

  clearChartDates() {
    this.chartStartDate = undefined;
    this.chartEndDate = undefined;
  }

  resetTopLayer() {
    const layerIds = Array.from(this.geoLayers.keys());
    layerIds.forEach((id) => {
      this.geoLayers.get(id)?.forEach((source) => {
        this.changeLayerZIndex(source as L.TileLayer.WMS, 100);
      });
    });
    this.layerOnTop = undefined;
    this.timelineOnTop = undefined;
  }

  get styleLayer() {
    return this.styledLayer;
  }

  get styleLayerId() {
    return this.styledLayerId ?? '';
  }

  set styleLayer(newLayer: Layer | undefined) {
    this.styledLayer = newLayer;
  }

  set styleLayerId(newId: string) {
    this.styledLayerId = newId;
  }
  
  getWFSUrl(baseUrl: string, layer: Layer): string {
    const url = new URL(baseUrl);
    url.href = url.origin + '/geoserver/wfs';
    url.searchParams.set('service', 'WFS');
    url.searchParams.set('version', '1.0.0');
    url.searchParams.set('request', 'GetFeature');
    url.searchParams.set('typeName', layer.wmsParams.layers);
    url.searchParams.set('outputFormat', 'application/json');
    url.searchParams.set('srsName', 'EPSG:4326');

    return url.toString();
  }

  getWFSGeoJSON(wfsUrl: string): Observable<any> {
    return this.http.get(wfsUrl);
  }

  getLayerLegend(url: string): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    };

    return this.http.get(url, httpOptions);
  }

  generateLinearScaleArray(scaleRange: Array<number>) {
    const initialValue = scaleRange[0];
    const finalValue = scaleRange[1];
    const deltaRange = finalValue - initialValue;
    const newScaleRange = [];
    let i = 0;
    newScaleRange.push(initialValue);
    for (i = 1; i <= 3; i++) {
      const value = initialValue + deltaRange / 4 * i;
      newScaleRange.push(value);
    }
    newScaleRange.push(finalValue);
    return newScaleRange.reverse();
  }

  getLegendUrl(baseurl: string, currentStyle?: string, palette?: string) {
 
    const url = new URL(baseurl);
    url.searchParams.delete('STYLES');
    url.searchParams.delete('STYLE');
    url.searchParams.append('colorbaronly', `true`);
    if (currentStyle) {
      url.searchParams.append('STYLES', `${currentStyle}`);
      url.searchParams.append('STYLE', `${currentStyle}`);
    }
    if (palette) {
      url.searchParams.append('PALETTE', `${palette}`);
    }
    return url.toString();
  }

  public removeLoadedOrder(orderID: number) {
    const indexToRemove = this.loadedOrders.findIndex((id) => id === orderID);
    if (indexToRemove > -1) {
      this.loadedOrders.splice(indexToRemove, 1);
    }
  }

  private isExternalGeoserver(layer: Layer) {
    return layer.dataDissemination && 
      layer.wmsType === 'WMS' && 
      !layer.geoServerURI.split('/').includes('ncWMS2');
  }
}
