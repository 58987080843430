import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import {environment} from "../../../environments/environment";

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-API-KEY': environment.apiKey,
  }),
};

export function handleError(error: HttpErrorResponse) {
  if (error.status === 0) console.error('An error occurred:', error.error);
  else
    console.error(
      `Backend returned code ${error.status}, body was: `,
      error.error
    );
  return throwError('Something bad happened; please try again later.');
}
