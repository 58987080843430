<!-- <h1 class="text-gray-700 font-bold">
  {{ "main-page.orders.register-aoi" | translate }}
</h1>
<div class="flex gap-4 mt-2">
  <app-button
    [leftIcon]="faDrawPolygon"
    (click)="draw('polygon')"
    [label]="'actions.draw-polygon' | translate"
  ></app-button>
  <app-button
    [leftIcon]="faMapPin"
    [label]="'actions.mark-point' | translate"
    (click)="draw('marker')"
  ></app-button>
</div>
<div>
  <date-picker
    [label]="'main-page.orders.execution-date' | translate"
    [minDate]="serviceStartDate"
    [maxDate]="serviceEndDate"
    (selectedDate)="executionDate.emit($event)"
  ></date-picker>
</div>
<div class="flex justify-end mt-4">
  <app-button
    [label]="'actions.order' | translate"
    (click)="makeOrder.emit()"
    [isDisabled]="isDisabled"
  ></app-button>
</div> -->

<ng-template
  [ngxPermissionsExcept]="['customer', 'seller']"
  [ngxPermissionsExceptElse]="signedOn"
  (permissionsAuthorized)="onAuthorizationChange(false)"
  (permissionsUnauthorized)="onAuthorizationChange(true)"
>
  {{ "main-page.orders.no-sign-on" | translate }}
</ng-template>
<ng-template #signedOn>
  <ng-container *ngIf="hasData; else loading">
    <div *ngIf="isSubscription" class="flex flex-col gap-1">
      <p class="text-primary-500 text-base">
        {{ 'main-page.orders.subscriptions.disclaimer' | translate }}
      </p>
      <div class="flex flex-row gap-1" *ngIf="storeURL">
        <p class="text-primary-500 text-base">
          {{ 'main-page.orders.subscriptions.storeLink.text' | translate }}
        </p>
        <a [href]="storeURL" target="_blank" class="text-primary-500 underline font-bold text-base">
          {{ 'main-page.orders.subscriptions.storeLink.urlText' | translate }}
        </a>
      </div>

    </div>
    <app-dynamic-form
      (submitForm)="emitData($event)"
      [isLoading]="isLoading"
      [inputs]="inputs"
      [allowedGeometry]="allowedGeometry"
    ></app-dynamic-form>
  </ng-container>
  <ng-template #loading>
    <spinner variant="rounded"></spinner>
  </ng-template>
</ng-template>
