<div
  [@slideInOut]
  class="
  z-50 
  absolute 
  top-24
  py-4
  pl-4
  bottom-20
  bg-white 
  rounded-lg 
  shadow-lg 
  w-1/4 
  opacity-90"
  [ngClass]="collapsedClass()"
>
  <ng-content></ng-content>
</div>
