import { locales, Options } from '@core';
import { format } from 'date-fns';

const serializeToLocaleDate = (date: string | Date, language: string) => {
  if (date) {
    const formatDate = new Date(date);

    return format(formatDate, 'P', {
      locale: locales[language]
    });
  } else {
    return '';
  }
};

const serializeToLocaleString = (date: string | Date, language: string) => {
  if (date) {
    const formatDate = new Date(date);

    return format(formatDate, 'dd MMM yyyy', {
      locale: locales[language]
    });
  } else {
    return '';
  }
};

const serializeDateToDateTime = (date: string | Date, language: string) => {
  if (date) {
    const formatDate = new Date(date);
    return format(formatDate, `dd/MM/yyyy HH:mm:ss`, {
      locale: locales[language]
    });
  } else {
    return '';
  }
};

const serializeParams = (options: Options) => {
  const params = [];
  for (let option in options) params.push(`${option}=${options[option]}`);
  const queryParams = params.join('&').replace(/ /g, '%20');

  return queryParams;
};

export {
  serializeParams,
  serializeToLocaleString,
  serializeToLocaleDate,
  serializeDateToDateTime
};
