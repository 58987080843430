import { faMap, faPenRuler } from '@fortawesome/free-solid-svg-icons';

const menus = [
  {
    slug: 'services',
    label: 'main-page.sidebar.services',
    icon: faMap,
    permissionOnly: '',
    isSelected: true,
    isDisabled: false
  },
  {
    slug: 'tools',
    label: 'main-page.sidebar.map-tools-menu',
    icon: faPenRuler,
    permissionOnly: '',
    isSelected: false,
    isDisabled: false
  }
  // {
  //   slug: 'orders',
  //   label: 'main-page.sidebar.orders',
  //   icon: faBoxOpen,
  //   permissionOnly: 'customer',
  //   isSelected: false,
  //   isDisabled: false
  // }
];

export { menus };
