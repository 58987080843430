import { NgModule, Optional, SkipSelf } from '@angular/core';
import { DynamicInputsControlService, InputService, MapService, ServicesService, TranslateService } from '@core/services';
import { MainLayoutModule } from 'features';
import { SharedModule } from 'shared';
import { TimelineService } from "./services";

@NgModule({
  declarations: [],
  exports: [SharedModule, MainLayoutModule],
  providers: [
    MapService,
    ServicesService,
    TranslateService,
    DynamicInputsControlService,
    InputService,
    TimelineService
]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('You should import core module only in the root module');
    }
  }
}
