<button (click)="onClick($event)" [disabled]="isDisabled">
  <fa-icon
    class="
    px-3
    py-2
    text-center
    hover:bg-gray-100
    text-asset
    rounded-full
    "
    [ngClass]="[disableIconClass(), iconClass]"
    [icon]="icon"
    [size]="size"
  ></fa-icon>
</button>
