<div *ngIf="isVisible" class="z-50 absolute right-20 bottom-20 p-4 bg-white rounded-lg shadow-lg opacity-90">
    <div class="flex justify-end -mr-2">
        <app-icon-button
          [icon]="faTimes"
          iconClass="hover:text-red-500"
          (clicked)="onCancel()"
        >
        </app-icon-button>
    </div>
    <div
      class="mb-4 mx-auto items-center justify-center text-center font-bold"
    >
    {{ 'main-page.layer-settings.title' | translate }}
    </div>
    <div class="mb-4 mx-auto items-center justify-center text-center">
      {{ layerName }}
    </div>
    <div class="mb-4 mx-auto items-center justify-center text-center">
      {{ layer?.wmsParams?.time }}
    </div>
    <div class="relative pt-1">
        <label for="opacitySelect" class="form-label">{{'main-page.layer-settings.opacity' | translate}}</label>
        <input
          type="range"
          class="
            form-range
            w-full
            h-2
            p-2
            bg-indigo-100
            focus:outline-none focus:ring-0 focus:shadow-none
          "
          min="0"
          max="1"
          step="0.1"
          id="opacitySelect"
          [(ngModel)]="opacity"
          (input)="onOpacityChange()"
        />
        <span>{{opacity}}</span>
      </div>
      <div *ngIf="hasStyles" class="relative pt-1">
        <label for="opacitySelect" class="form-label">{{'main-page.layer-settings.styles' | translate}}</label>
        <select id="styleSelect"
        [(ngModel)]="selectedStyle"
        (change)="onStyleChange()"
        class="w-full my-4 border-gray-200 leading-tight rounded">
        <option *ngFor="let style of layerStyles" [ngValue]="style.value">{{style.label}}</option>
      </select>
      </div>
      

    <!-- <app-select
      id="style"
      groupBy="code"
      [placeholder]="'Select Style'"
      (propagate)="onStyleChange($event)"
      [value]="selectedStyle"
      [options]="layerStyles"
      [label]="'Styles'"
    ></app-select> -->
</div>