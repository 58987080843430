<div class="max-w-3xl">
  <p>{{ description }}</p>
</div>
<hr class="separator" />
<div class="w-full flex justify-between mt-1 mb-2">
  <div class="block w-1/3 mr-4">
    <small class="text-secondary font-bold uppercase">{{
      "main-page.orders.service-registration" | translate
    }}</small>
    <p>{{ created }}</p>
  </div>
  <div class="block w-1/3 mr-4">
    <small class="text-secondary font-bold uppercase">{{
      "main-page.orders.service-provider" | translate
    }}</small>
    <p>
      {{
        provider?.organizationName ||
          ("main-page.orders.service-unknown-provider" | translate)
      }}
    </p>
  </div>
  <div class="block w-1/3">
    <small class="text-secondary font-bold uppercase">{{
      "main-page.orders.service-type" | translate
    }}</small>
    <div>
      <div class="flex flex-wrap">
        <span *ngFor="let type of types()">
          <small class="text-primary font-bold underline mr-4">{{
            "main-page.orders." + slugify(type) | translate
          }}</small>
        </span>
      </div>
    </div>
  </div>
</div>
<div>
  <small class="text-secondary font-bold uppercase">{{
    "main-page.orders.service-keywords" | translate
  }}</small>
  <div class="flex mb-1">
    <div class="keywords flex flex-wrap">
      <span *ngFor="let keyword of keywords">
        <small class="keyword font-bold underline">{{ keyword }}</small>
      </span>
    </div>
    <a
      *ngIf="documentationURI"
      [href]="documentationURI"
      target="_blank"
      class="
          terms-conditions
          text-secondary
          font-bold
          uppercase
          underline
          place-self-end
        "
    >
      <small>
        {{ "main-page.orders.terms-conditions" | translate }}
      </small>
    </a>
  </div>
</div>
<hr class="separator" />
