import { Component, OnInit } from '@angular/core';
import { TimelineService } from '@core';

@Component({
  selector: 'app-layer-legend',
  templateUrl: './layer-legend.component.html',
  styleUrls: ['./layer-legend.component.scss']
})
export class LayerLegendComponent implements OnInit {

  constructor(private timelineService: TimelineService) { }

  ngOnInit(): void {
  }

  get scaleRange() {
    return this.timelineService.ncwmsScaleRange;
  }

  get scaleUnit() {
    return this.timelineService.ncwmsScaleUnits;
  }

  get imageURL() {
    return this.timelineService.ncwmsLegendURL;
  }

  get showLegend() {
    return this.timelineService.isLegendVisible;
  }

  get gradient() {
    const colors = this.timelineService.gradientColorList;
    if (colors) {
      return 'linear-gradient(90deg, ' + colors.join(', ') + ')';
    } else {
      return '';
    }
  }

  get showGradient() {
    return this.timelineService.isGradientLegendVisible && this.timelineService.gradientColorList;
  }

}
