import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapService, Menu, menus } from '@core';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSlash,
  faDrawPolygon
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menus = menus;

  isCollapsed: boolean = JSON.parse(localStorage.getItem('sidebar_expanded') ?? 'false');

  @Input() activeMenu?: string;

  isMenuVisible: boolean[] = [];

  faSlash = faSlash;
  faDrawPolygon = faDrawPolygon;

  constructor(private router: Router, private mapService: MapService) {}

  ngOnInit(): void {
    this.setActiveMenu();
    this.menus.forEach(() => this.isMenuVisible.push(true));
  }

  get collapseIcon() {
    return this.isCollapsed ? faAngleDoubleLeft : faAngleDoubleRight;
  }

  onMenuChange(index: number) {
    this.router.navigate([], {
      queryParams: {
        menu: this.menus[index].slug
      },
      queryParamsHandling: 'merge'
    });

    this.menus.map(menu => (menu.isSelected = false));
    this.menus[index].isSelected = true;
  }

  isSelectedMenu(menu: Menu, slug: string) {
    return menu.slug === slug && menu.isSelected;
  }

  collapsedClass() {
    return this.isCollapsed ? 'w-40' : 'w-18';
  }

  setActiveMenu() {
    const index = this.menus.findIndex(menu => menu.slug === this.activeMenu);

    if (index > -1) this.onMenuChange(index);
  }

  onCollapse() {
    this.isCollapsed = !this.isCollapsed;
    localStorage.setItem('sidebar_expanded', JSON.stringify(this.isCollapsed));
  }

  toggleVisibility(index: number) {
    this.isMenuVisible[index] = !this.isMenuVisible[index];
  }
}
