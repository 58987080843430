import { Injectable } from '@angular/core';
import axios from 'axios';
import { GeocoderSearchResult } from '@core'
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OsmNominatimService {

  osmBaseUrl: string = 'https://nominatim.openstreetmap.org/search';

  constructor(private http: HttpClient) { }

  geocodeSearch(format: string, query: string): Promise<GeocoderSearchResult> {
    const url = `${this.osmBaseUrl}?format=${format}&q=${query}`;
    const   axiosConfig = {
      method: 'get',
      url: url,
      headers: {}
    };

    return axios(axiosConfig);
  }
}
