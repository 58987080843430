import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '../../../@core';

@Component({
  selector: 'app-lang-dropdown',
  templateUrl: './lang-dropdown.component.html',
  styleUrls: ['./lang-dropdown.component.scss']
})
export class LangDropdownComponent implements OnInit {
  isDropdownOpen: boolean = false;
  lang: string = '';
  @Output() selectedLanguage = new EventEmitter();
  flag: string = 'fi-fr';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.setLang(this.translateService.currentLanguage);
  }

  setLang(lang: string): void {
    this.isDropdownOpen = false;
    this.lang = lang;
    this.flag = lang === 'en' ? `fi-gb` : `fi-${lang}`;
    this.selectedLanguage.emit(lang);
  }
}
