import * as L from 'leaflet';

//https://gist.github.com/bmcbride/4248238
function toWkt(layer: any) {
  let lng,
    lat,
    coords = [];
  if (layer instanceof L.Polygon || layer instanceof L.Polyline) {
    const latlngs: any = layer.getLatLngs();
    for (let i = 0; i < latlngs.length; i++) {
      const latlngs1: any = latlngs[i];
      if (latlngs1.length) {
        for (let j = 0; j < latlngs1.length; j++) {
          coords.push(latlngs1[j].lng + ' ' + latlngs1[j].lat);
          if (j === 0) {
            lng = latlngs1[j].lng;
            lat = latlngs1[j].lat;
          }
        }
      } else {
        coords.push(latlngs[i].lng + ' ' + latlngs[i].lat);
        if (i === 0) {
          lng = latlngs[i].lng;
          lat = latlngs[i].lat;
        }
      }
    }
    if (layer instanceof L.Polygon) {
      return 'POLYGON((' + coords.join(',') + ',' + lng + ' ' + lat + '))';
    } else if (layer instanceof L.Polyline) {
      return 'LINESTRING(' + coords.join(',') + ')';
    }
  } else if (layer instanceof L.Marker) {
    return 'POINT(' + layer.getLatLng().lng + ' ' + layer.getLatLng().lat + ')';
  }
  return;
}

function toLatLngs(layer: L.Polygon | L.Polyline | L.Marker) {
  const geoJSON = layer.toGeoJSON();
  let latLngs = geoJSON.geometry.coordinates;
  let points = geoJSON.geometry.coordinates.length;

  if (layer instanceof L.Polygon) {
    const polyLatLngs: any = latLngs[0];
    polyLatLngs.splice(polyLatLngs.length - 1, 1);
    const polyPoints = polyLatLngs.length;
    return { latLngs: polyLatLngs, points: polyPoints };
  } else if (layer instanceof L.Polyline) return { latLngs, points };
  else return { latLngs: [latLngs], points };
}

function toDegreesMinutesAndSeconds(latLng: number) {
  const absolute = Math.abs(latLng);

  const degrees = Math.floor(absolute);
  const subtract = (absolute - degrees) * 60;
  const minutes = parseFloat(subtract.toFixed(3));
  const floorMinutes = Math.floor(subtract);
  const seconds = parseFloat(((subtract - floorMinutes) * 60).toFixed(2));

  const raw = () => ({ degrees, minutes, seconds });

  return { parsed: `${degrees}° ${minutes}'`, raw };
}

function toDms(lat: number, lng: number) {
  const latitude = toDegreesMinutesAndSeconds(lat).parsed;
  const latitudeDirection = lat >= 0 ? 'N' : 'S';

  const longitude = toDegreesMinutesAndSeconds(lng).parsed;
  const longitudeDirection = lng >= 0 ? 'E' : 'W';

  const rawLat = toDegreesMinutesAndSeconds(lat).raw();
  const rawLng = toDegreesMinutesAndSeconds(lng).raw();

  return {
    lat: `${latitude} ${latitudeDirection}`,
    lng: `${longitude} ${longitudeDirection}`,
    rawLat: {
      ...rawLat,
      direction: latitudeDirection
    },
    rawLng: {
      ...rawLng,
      direction: longitudeDirection
    }
  };
}

const slugify = (...args: (string | number)[]): string => {
  const value = args.join(' ');

  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '-');
};

const omitKeys = (object: any, keys: string | string[]) => {
  let target: { [key: string]: string } = {};

  for (const key in object) {
    if (keys.indexOf(key) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(object, key)) continue;

    target[key] = object[key];
  }

  return target;
};

export { toDms, toLatLngs, toWkt, slugify, omitKeys };
