<div>
  <app-sidebar [activeMenu]="activeMenu"></app-sidebar>
</div>

<!-- <div
  class="timeline-container fixed bottom-20 transition
duration-500
ease"
>
  <app-timeline
    *ngIf="isActive"
    [label]="layersState.label"
    [@timelineAppear]
    class="fixed top-24 right-10 transition duration-300 ease-in"
  ></app-timeline>
</div> -->

<div
  class="timeline-container fixed bottom-20 transition
duration-500
ease"
>
  <div id="{{idx}}"
    class="fixed top-24 right-10"
    *ngFor="let layerKey of objectKeys(layers); index as idx"
    [ngStyle]="{'z-index': isTimelineOnTop(layerKey) ? '100' : idx, 'opacity': reduceOpacity(idx, layerKey) ? '0.2' : '1'}"
  >
    <div class="flex bg-white justify-between items-center p-1 rounded-t-lg">
      <app-timeline
        *ngIf="layers[layerKey].active"
        [id]="layerKey"
        [label]="layerKey"
        [@timelineAppear]
        class="fixed top-24 right-10 transition duration-300 ease-in"
      ></app-timeline>
    </div>
  </div>
</div>

<div class="w-full z-10">
  <app-map></app-map>
</div>

<!--<div class="draggable-container">
  <div class="timeline-container">
    <app-timeline
      *ngIf="isTimelineActive"
      cdkDragBoundary=".draggable-container"
      [@timelineAppear]="!isTimelineActive? 'in' : 'out'"
      ngResizable [rzHandles]="'e'"
      (rzStop)="onResizeStop()"
      (cdkDragEnded)="cdkDragEnded($event)"
      cdkDrag
    ></app-timeline>
  </div>
</div>-->
