<div 
*ngIf="hasFeatureInfo"
class="z-50 absolute right-20 top-60 p-4 bg-white rounded-lg shadow-lg opacity-90">
    <div class="flex justify-end -mr-2">
      <app-icon-button
        [icon]="faTimes"
        iconClass="hover:text-red-500"
        (clicked)="onCancel()"
      >
      </app-icon-button>
    </div>
    <div
      class="mb-4 mx-auto items-center justify-center text-center font-bold"
    >
    {{ "main-page.identify.title" | translate }}
    </div>
    <div class="row justify-between">
      <span class="text-asset">{{'main-page.identify.lat' | translate}}: </span>
      <span>{{featureInfo?.latitude}}</span>
    </div>
    <div class="row">
      <span class="text-asset">{{'main-page.identify.lon' | translate}}: </span>
      <span class="right-0">{{featureInfo?.longitude}}</span>
    </div>
    <div
                    class="row content overflow-auto"
                    *ngFor="let data of featureInfo?.featureInfoData"
                  >
                  <br>
                    <div class="row">
                      <span class="text-asset">{{ 'main-page.identify.layer' | translate }}: </span>
                      <span>
                        {{ data.layerName }}
                      </span>
                    </div>
                    <div *ngIf="data.time" class="row">
                      <span class="text-asset">{{'main-page.identify.time' | translate}}: </span>
                      <span>
                        {{ getFormattedDatetime(data.time) | date: 'dd-MM-yyyy HH:mm' }}
                      </span>
                    </div>

                    
                    <div class="row" *ngFor="let feature of data.featureData">
                      <hr class="border-2 mb-1 mt-1">
                      <div class="row" *ngFor="let key of getMapKeys(feature)">
                        <span class="text-asset">{{ key }}: </span>
                        <span>
                            {{ feature.get(key) }}
                        </span>
                      </div>
                        
                    </div>
      </div>
</div>
