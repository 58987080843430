import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { DynamicInputBase } from 'shared';
import { ServicesService } from '../services/services.service';
import { serviceInput } from '../../models';
import { take } from 'rxjs/operators';
import { AreaOfInterestInput } from 'shared';
import { TimeOfInterestInput } from 'shared';
import { DropdownInput } from 'shared';
import { TextboxInput } from 'shared';

@Injectable()
export class InputService {
  constructor(private geoRecordServices: ServicesService) {}

  getInputs(
      geoRecordIdentifier: string,
      geoRecordType?: string,
      servicePeriod?: { serviceStart: Date; serviceStop: Date },
      serviceFootprint?: string
  ) {
    let dynamicInputs: Array<DynamicInputBase<any>> = [];
    dynamicInputs = this.getInputsfromServer(
        geoRecordIdentifier,
        servicePeriod ?? servicePeriod,
        serviceFootprint ?? serviceFootprint
    );
    return of(dynamicInputs);
  }

  getInputsfromServer(
      geoRecordIdentifier: string,
      servicePeriod: { serviceStart: Date; serviceStop: Date } | undefined,
      serviceFootprint?: string
  ) {
    let dynamicInputs: Array<DynamicInputBase<any>> = [];

    this.geoRecordServices
        .getServiceDescription(geoRecordIdentifier)
        .pipe(take(1))
        .subscribe(
            (description) => {
              let aoiInput = new AreaOfInterestInput({
                key: 'aoi',
                label: 'main-page.services.instructions.dynamic-inputs.area-of-interest',
                required: true,
                type: 'string',
                value: '',
                order: 1,
                serviceFootprint: serviceFootprint,
              });
              let toiInput = new TimeOfInterestInput({
                key: 'toi',
                label: 'main-page.services.instructions.dynamic-inputs.time-of-interest',
                required: false,
                type: 'date',
                value: '',
                order: 1,
                serviceStartDate: servicePeriod?.serviceStart,
                serviceEndDate: servicePeriod?.serviceStop,
              });
              dynamicInputs.push(aoiInput, toiInput);
              description.processDescription.input.map((input, index) => {
                if (!input.identifier.includes('TRIGGERING') && input.identifier !== 'AOI') {
                  dynamicInputs.push(this.handleExtraInputs(input, index));
                }
              });
            },
            (error) => {
              let aoiInput = new AreaOfInterestInput({
                key: 'aoi',
                label: 'main-page.services.instructions.dynamic-inputs.area-of-interest',
                required: true,
                type: 'string',
                value: '',
                order: 1,
                serviceFootprint: serviceFootprint,
              });
              dynamicInputs.push(aoiInput);
              let toiInput = new TimeOfInterestInput({
                key: 'toi',
                label: 'main-page.services.instructions.dynamic-inputs.time-of-interest',
                required: false,
                type: 'date',
                value: '',
                order: 1,
                serviceStartDate: servicePeriod?.serviceStart,
                serviceEndDate: servicePeriod?.serviceStop,
              });
              dynamicInputs.push(toiInput);
            }
        );
    return dynamicInputs;
  }

  handleExtraInputs(
      input: serviceInput,
      index: number
  ): DynamicInputBase<string> {
    if (
        !input.literalData.anyValue &&
        input.literalData.value &&
        input.literalData.value?.length > 0
    ) {
      let options: {
        key: string;
        value: string;
      }[] = [];
      input.literalData.value.map((value) => {
        options.push({
          key: value as string,
          value: value as string,
        });
      });
      return new DropdownInput({
        key: input.identifier,
        label: input.title,
        required: input.minOccurs == 1,
        options,
        order: index,
      });
    } else {
      return new TextboxInput({
        key: input.identifier,
        label: input.title,
        required: input.minOccurs == 1,
        order: index,
      });
    }
  }
}

//  = [
//   new DropdownInput({
//     key: 'brave',
//     label: 'Bravery Rating',
//     options: [
//       {key: 'solid', value: 'Solid'},
//       {key: 'great', value: 'Great'},
//       {key: 'good', value: 'Good'},
//       {key: 'unproven', value: 'Unproven'},
//     ],
//     order: 3,
//   }),
//
//   new TextboxInput({
//     key: 'firstName',
//     label: 'First name',
//     value: 'Bombasto',
//     required: true,
//     order: 1,
//   }),
//
//   new TextboxInput({
//     key: 'emailAddress',
//     label: 'Email',
//     type: 'email',
//     order: 2,
//   }),
// ];
