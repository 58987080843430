
<div class="chart-content z-50 absolute right-20 top-20 px-4 bg-white rounded-lg shadow-lg opacity-90">
  <div class="flex justify-end -mr-2" *ngIf="isChartActive">
    <app-icon-button
      [icon]="faTimes"
      iconClass="hover:text-red-500"
      (clicked)="onCancel()"
    >
    </app-icon-button>
  </div>
  <div id="chart"></div>
  <div *ngIf="isChartActive" class="py-2">
      <app-date-picker
      
      (selectedDate)="onDateSelected($event)"
      [label]="'main-page.sidebar.advanced-search.date' | translate"
    ></app-date-picker>
  </div>

</div>
