import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EventOption } from '@core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() id!: string;
  @Input() type: string = 'text';
  @Input() label?: string;
  @Input() disabled: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() placeholder: string = '';
  @Input('value') _value?: string | number | null = '';
  @Input() helperText?: string;
  @Input() error: boolean = false;
  @Input() classes = 'py-2';
  @Input() labelSize = 'text-md';
  @Input() icon?: IconDefinition;
  @Input() prefix?: string;
  @Input() isIconClickable: boolean = false;
  @Input() isIconDisabled: boolean = false;

  @Output() inputChange = new EventEmitter<EventOption>();
  @Output() iconClick = new EventEmitter<Event>();

  onChange: (args?: string | number | null) => void = () => {};
  onTouched: () => void = () => {};

  constructor() {}

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  ngOnInit(): void {}

  writeValue(value: string): void {
    if (value) this._value = value;
  }

  registerOnChange(fn: InputComponent['onChange']): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: InputComponent['onTouched']): void {
    this.onTouched = fn;
  }

  onInputChange(value: EventOption['value']) {
    this.inputChange.emit({ value, id: this.id });
  }

  onIconClick(event: Event) {
    if (!this.isIconDisabled) this.iconClick.emit(event);
  }

  clickableIconClass() {
    return this.isIconDisabled
      ? 'text-gray-700 cursor-not-allowed'
      : this.isIconClickable
      ? 'cursor-pointer hover:bg-gray-100 hover:text-primary rounded-full'
      : '';
  }
}
