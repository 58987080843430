import { AfterViewInit, Component } from '@angular/core';
import { MapService } from '@core/services/map/map.service';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {
  constructor(private mapService: MapService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.mapService.initializeMap('map', [0, 0], 3);
      this.mapService.replaceBasemap(
        this.mapService.namesOfBaseMaps['Esri Satellite']
      );
    }, 200);
  }

  ngOnDestroy(): void {
    this.mapService.destroyMap();
  }
}
