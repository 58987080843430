import { Component, OnInit } from '@angular/core';

import { environment } from '../../../../environments/environment';
import * as pkg from '../../../../../package.json';
import { MapService } from '@core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  env = environment;
  pkg = pkg;

  constructor(private mapService: MapService) {}

  get coordinates() {
    return this.mapService.cursorLatLng;
  }

  get scale(){
    return this.mapService.scale;
  }

  ngOnInit(): void {}
}
