import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpOptions, NewOrder, Order, OrderPermission } from '@core';
import { map, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private ordersURL = `${environment.apiEndpoint}/v1/order`;
  private userPermissionURL = `${environment.apiEndpoint}/v1/auth/validate-order`;

  constructor(private httpService: HttpClient) {}

  getAllUserOrders() {
    return this.httpService.get<Order[]>(`${this.ordersURL}`, httpOptions).pipe(
      take(1)
    );
  }

  createNewOrder(payload: NewOrder) {
    return this.httpService
      .post<Order>(this.ordersURL, payload, httpOptions)
      .pipe(
        take(1),
        tap(data => data),
        map(data => data)
      );
  }

  downloadOrderResult(productId: string) {
    const httpOptionsWithResponseType = {
      responseType: 'text' as 'json',
      ...httpOptions
    };

    return this.httpService.get<string>(
      environment.apiEndpoint + '/v1/product/' + productId + '/download',
      httpOptionsWithResponseType
    );
  }

  checkCommercialUserPermission(serviceIdentifier: string, serviceLicence: string): Observable<OrderPermission> {
    const payload = {
      serviceId: serviceIdentifier,
      type: serviceLicence
    };
    return this.httpService.post<OrderPermission>(`${this.userPermissionURL}`, payload, httpOptions);
  }
}
