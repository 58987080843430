import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IMyDateRangeModel } from 'angular-mydatepicker';
import * as highcharts from 'highcharts';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export class TimeParameter {
  name: string = ''
  title: string = ''
  units: string = ''
  data: [Date, number][] = []
  yAxisNumber: number = 0
  plotType: string = ''
}

@Component({
  selector: 'app-series-chart',
  templateUrl: './series-chart.component.html',
  styleUrls: ['./series-chart.component.scss']
})
export class SeriesChartComponent implements OnInit {

  private chartRef: highcharts.Chart | undefined = undefined;
  private chartTitle?: string;

  parameters: TimeParameter[] = [];

  @Output() dateChange = new EventEmitter<[Date, Date]>();
  @Output() cancel = new EventEmitter();

  faTimes = faTimes;

  constructor() { }

  ngOnInit(): void {
  }


  public clear() {
    this.parameters = [];
    if (this.chartRef) {
        this.chartRef.destroy();
    }
    this.chartRef = undefined;
  }

  public get numberOfParameters() {
    return this.parameters.length;
  }

  public get isChartActive() {
    return this.chartRef;
  }

  public set newChartTitle(title: string) {
    this.chartTitle = title;
  }

  private series(seriesName: string, data: [Date, number][], yAxisNumber: number, lineType: string, units: string) {
    if (!units) {
      units = '';
    }
    return {
      type: lineType,
      name: seriesName,
      data: data.map(p => {
        return {
          x: p[0].getTime(),
          y: p[1]
        };
      }),
      yAxis: yAxisNumber,
      tooltip: {
        valueSuffix: ' ' + units
      }
    };
  }

  public addParameter(name: string, title: string, units: string, data: [Date, number][], yAxisNumber?: number, plotType?: string) {
    if (!yAxisNumber) {
      yAxisNumber = this.parameters.length % 2;
    }
    this.parameters.push({
      name: name,
      title: title,
      units: units,
      yAxisNumber: yAxisNumber,
      data: data,
      plotType: plotType ? plotType : 'spline'
    });
  }

  public removeParameter(name: string, units: string) {
    const parameterToRemove = this.parameters.find(param => param.name === name && param.units === units);
    if (parameterToRemove !== undefined) {
      const paramIndex = this.parameters.indexOf(parameterToRemove, 0);
      this.parameters.splice(paramIndex, 1);
    } else {
      console.log('Could not remove parameter')
    }
  }

  private yAxis(name: string, units: string, colorIndex: number, isReversed: boolean,
    isOpposite?: boolean, mininum?: number, maximum?: number, tickInt?: number) {
    let label;
    const colors = highcharts.getOptions().colors;
    if (units !== undefined) {
      label = `${name} (${units})`;
    } else {
      label = `${name}`;
    }
    return {
      title: {
        text: label,
        style: {
          color: colors? colors[colorIndex] : undefined
        }
      },
      labels: {
        style: {
          color: colors? colors[colorIndex] : undefined
        }
      },
      opposite: isOpposite,
      min: mininum,
      max: maximum,
      tickInterval: tickInt,
      reversed: isReversed


    };
  }

  public drawChart() {
    if (this.parameters.length > 0) {
      let yAxisItem = [];
      let seriesItem = [];

      yAxisItem = this.parameters.map((parameter, index) => {
        return this.yAxis(parameter.title, parameter.units, index, false, index % 2 === 0)
      });

      seriesItem = this.parameters.map((parameter, index) => {
        return this.series(parameter.title, parameter.data, parameter.yAxisNumber, parameter.plotType, parameter.units);
      })

      const chartOptions = {
        chart: {
          zoomType: 'xy'
        },
        title: {
          text: this.chartTitle
        },
        legend: {
          enabled: true
        },
        plotOptions: {
          series: {
            turboThreshold: 0
          }
        },
        xAxis: {
          type: 'datetime',
      },
        yAxis: yAxisItem,
        series: seriesItem,
        // tooltip: nTooltip
      }
      this.chartRef = (highcharts as any).chart('chart', chartOptions)
    }
  }

  onDateSelected(dateRange: IMyDateRangeModel) {
    const dateFormat = "yyyy-MM-dd'T'HH:mm:ss";
    if (dateRange?.beginJsDate && dateRange?.endJsDate) {
      let beginDate = new Date(dateRange.beginJsDate);
      let endDate = new Date(dateRange.endJsDate);

      this.dateChange.emit([beginDate, endDate])
    }
  }

  onCancel() {
    this.clear();
    this.cancel.emit();
  }
}
