import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.scss']
})
export class SidebarButtonComponent implements OnInit {
  isHover: boolean = false;
  @Input() icon?: IconDefinition;
  @Input() iconSize?: SizeProp;
  @Input() disabled!: boolean;
  @Input() label?: string;
  @Input() labelClasses: string = 'text-sm';
  @Input() textColor?: string;
  @Input() hover?: string;
  @Input() background?: string;
  @Input() isSelected: boolean = false;
  @Input() classes?: string;
  @Output() clicked = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit(): void {}

  onClick(event: MouseEvent) {
    if (!this.disabled) this.clicked.emit(event);
  }

  selectedClass() {
    return {
      'text-white': this.isHover,
      'text-asset': !this.isHover,
      'bg-asset text-gray-50': this.isSelected,
      'inline-flex items-center leading-sm mr-3': this.label,
      'text-gray-300 hover:cursor-default hover:bg-gray-200': this.disabled
    };
  }
}
