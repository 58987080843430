import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  DynamicInputsControlService,
  NewOrder,
  OrderExtraInput,
} from '@core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { DynamicInputBase } from '../classes';
import { TimeOfInterestInput } from '../classes';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  //providers: [DynamicInputsControlService],
})
export class DynamicFormComponent implements OnInit {
  @Input() inputs: DynamicInputBase<any>[] | TimeOfInterestInput[] | null = [];
  @Input() isLoading: boolean = false
  @Input() allowedGeometry?: string;
  faCircleNotch = faCircleNotch

  @Output() submitForm = new EventEmitter<NewOrder>();
  @Output() action = new EventEmitter<string>();

  form!: UntypedFormGroup;
  payLoad = '';

  defaultStartDate?: Date;
  defaultEndDate?: Date;

  constructor(
    private dynamicInputsControlService: DynamicInputsControlService
  ) {}

  ngOnChanges() {}

  ngOnInit() {
    if (this.inputs) {
      const timeInput: TimeOfInterestInput = (this.inputs as any[]).find((input) => input.controlType === 'TOI');
      if (timeInput) {
        this.defaultStartDate = timeInput.serviceStartDate;
        this.defaultEndDate = timeInput.serviceEndDate;
      }
      this.inputs.sort(function (a, b) {
        return a.order - b.order;
      });
      this.form = this.dynamicInputsControlService.toFormGroup(this.inputs);
    }
  }

  onSubmit() {
    let toi = this.form.get('toi')?.value.split(',');
    let newOrder: NewOrder = {
      aoi: this.form.get('aoi')?.value,
      beginAt: toi[0] ? toi[0] : (this.defaultStartDate ? this.defaultStartDate : null),
      endAt: toi[1] ? toi[1] : (this.defaultEndDate ? this.defaultEndDate : null),
      inputs: new Array<OrderExtraInput>(),
      serviceId: null,
      isSubscription: false,
      timeStep: null
    };
    Object.keys(this.form.controls).map((key) => {
      if (key !== 'aoi' && key !== 'toi') {
        newOrder.inputs?.push({
          identifier: key,
          value: this.form.get(key)?.value,
        });
      }
    });
    this.submitForm.emit(newOrder);
  }
}
