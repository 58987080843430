<div
  class="border-solid border-2 border-gray-100 rounded-md p-4 hover:bg-gray-100 transition duration-300 ease-in-out"
>
  <div class="flex justify-between ">
    <p [title]="title">
      <span class="font-bold text-asset text-primary-500">{{ label }}</span> -
      {{ secondLabel }}
    </p>
    <p
      (click)="onSeeDetails($event)"
      class="hover:underline hover:cursor-pointer"
      [ngClass]="labelClass()"
    >
      {{
        isCollapsed
          ? ("actions.hide.details" | translate)
          : ("actions.show.details" | translate)
      }}
    </p>
  </div>
  <div *ngIf="isCollapsed" class="w-full flex-col">
    <hr class="w-full border-4 border-gray-200 my-4" />
    <ng-content></ng-content>
  </div>
</div>
