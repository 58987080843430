import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input() label?: string;
  @Input() secondLabel?: string;
  @Input() title?: string;
  @Input() isCollapsed?: boolean;

  @Output() details = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onSeeDetails(event: MouseEvent) {
    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed) this.details.emit(event);
  }

  labelClass() {
    return { 'text-asset font-bold': this.isCollapsed };
  }
}
