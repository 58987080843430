<div
  *ngIf="isSnackbarActive"
  class="
    z-50
    snackbar
    {{ snackbarService.snackbarConfig.snackbar.bgColor }}
    {{ snackbarService.snackbarConfig.snackbar.textColor }}
    rounded-lg
    px-4
    py-3
    shadow-md
  "
  role="alert"
>
  <div class="flex">
    <div class="py-1">
      <fa-icon
        size="2x"
        class="fill-current mr-4"
        [icon]="snackbarService.snackbarConfig.snackbar.icon"
      ></fa-icon>
    </div>
    <div>
      <p class="font-bold">{{ snackbarService.title }}</p>
      <p class="text-sm">{{ snackbarService.message }}</p>
    </div>
  </div>
</div>
