<div>
    <div class="py-2 w-full">
        <input type="file" accept=".json, .geojson, .tif, .tiff, .geotiff, .zip" style="display: none"
        #file id="fileUp"
        (change)="fileChange($event)"
        (click)="onBrowseClick($event)"/>
        <!-- <button class="browse-button" tabindex="-1" (click)="file.click()">{{ 'sidebar.layers.drag-drop-files.browse-files' | translate }}</button> -->
        <app-button
            [label]="'main-page.sidebar.map-tools.browse-file' | translate"
            [leftIcon]="faFileArrowUp"
            (click)="file.click()"
        >
        </app-button>
    </div>
    
    <div class="w-full py-2">
        <input class="text-input" [(ngModel)]="layerUrlText" type="text" placeholder="{{ 'main-page.sidebar.map-tools.remote-url' | translate }}"/>
        <!-- <button mat-raised-button class="url-button" (click)="readInputUrl()" [disabled]="isInputUrlEmpty"><fa-icon 
            class="icon"
            [icon]="faUpload"
            aria-hidden="true"
            ></fa-icon>
        </button> -->
        <app-icon-button
            iconClass="px-0 py-0"
            [icon]="faUpload"
            (clicked)="readInputUrl()"
        >
        </app-icon-button>
    </div>
    <div class="flex w-full justify-between" *ngFor="let layer of uploadedLayers">
        <span>{{ layer.layerKey }}</span>
        <div>
            <app-icon-button
            [icon]="faTimes"
            iconClass="hover:text-red-500"
            (clicked)="onRemoveClick(layer)"
            >
            </app-icon-button>
            <app-icon-button
            [icon]="faArrowsToCircle"
            iconClass="hover:text-red-500"
            (clicked)="onZoomClick(layer)"
            >
            </app-icon-button>
        </div>
        
    </div>
</div>