<div class="z-50 absolute right-20 top-20 p-4 bg-white rounded-lg shadow-lg opacity-90">
  <div class="flex justify-end -mr-2">
    <app-icon-button
      [icon]="faTimes"
      iconClass="hover:text-red-500"
      (clicked)="onCancel()"
    >
    </app-icon-button>
  </div>
  <div
    class="mb-4 mx-auto items-center justify-center text-center font-bold"
  >
  {{ 'main-page.timeline.animation.title' | translate }}
  </div>
  <div class="w-full my-4 px-2">
    <div class="flex flex-col">
      <label class="text-gray-700">{{ 'main-page.timeline.animation.framerate' | translate }}</label>
      <input type="number" min="1" max="30" step="1" [(ngModel)]="frameRate"/>
    </div>
  
    <app-date-picker
      (selectedDate)="onDateSelected($event)"
      [label]="'main-page.sidebar.advanced-search.date' | translate"
    ></app-date-picker>

    <div class="flex flex-col mt-2">
      <app-button
        [isDisabled]="submitDisabled"
        (click)="onSubmit()"
        [label]="'main-page.timeline.animation.submit' | translate"
      ></app-button>
    </div>
  </div>
    
</div>
