import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCircle, IconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {
  @Input() isDisabled: boolean = false;
  @Input() iconClass: string | string[] = '';
  @Input() icon: IconDefinition = faCircle;
  @Input() size: SizeProp = '1x';
  @Output() clicked = new EventEmitter<MouseEvent>();
  constructor() {}

  ngOnInit(): void {}

  onClick(event: MouseEvent) {
    this.clicked.emit(event);
  }

  disableIconClass() {
    return this.isDisabled
      ? 'text-gray-300 hover:cursor-not-allowed'
      : 'hover:text-asset-600';
  }
}
