<div class="relative">
	<button
			(click)="isDropdownOpen = !isDropdownOpen"
			class="relative z-10 block rounded-md hover:bg-gray-50 py-2 px-4 focus:outline-none text-asset  hover:shadow duration:300">
		<span class="fi {{flag}} "></span>
		<span class="uppercase"> {{lang}}</span>
	</button>

	<div
			(outsideClick)="isDropdownOpen = !isDropdownOpen"
			*ngIf="isDropdownOpen"
			class="absolute right-0 mt-2 py-2 w-28 bg-white rounded-md shadow-xl z-20">
		<button
				(click)="setLang('de')"
				class="block w-full px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gradient-to-r hover:from-primary hover:to-primary-600 hover:text-white text-center"
		>
			<span class="fi fi-de "></span>
			DE
		</button>
		<button
				(click)="setLang('en')"
				class="block w-full px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gradient-to-r hover:from-primary hover:to-primary-600 hover:text-white text-center"
		>
			<span class="fi fi-gb "></span>
			EN
		</button>
		<button
				(click)="setLang('fr')"
				class="block w-full px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gradient-to-r hover:from-primary hover:to-primary-600 hover:text-white text-center"
		>
			<span class="fi fi-fr "></span>
			FR
		</button>
		<button
				(click)="setLang('pt')"
				class="block w-full px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gradient-to-r hover:from-primary hover:to-primary-600 hover:text-white text-center"
		>
			<span class="fi fi-pt "></span>
			PT
		</button>
		<button
				(click)="setLang('ro')"
				class="block w-full px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gradient-to-r hover:from-primary hover:to-primary-600 hover:text-white text-center"
		>
			<span class="fi fi-ro "></span>
			RO
		</button>

	</div>
</div>
