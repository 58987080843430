import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicInputBase } from 'shared/components/dynamics/dynamic-input-base';

@Injectable()
export class DynamicInputsControlService {

  toFormGroup(inputs: DynamicInputBase<any>[] | null) {
    const group: any = {};
    if (inputs)
      inputs.forEach((input) => {
        group[input.key] = input.required
          ? new UntypedFormControl(input.value || '', Validators.required)
          : new UntypedFormControl(input.value || '');
      });
    return new UntypedFormGroup(group);
  }
}
