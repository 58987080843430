import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  AccordionComponent,
  ButtonComponent,
  CheckboxesComponent,
  DatePickerComponent,
  DefaultCardComponent,
  DefaultDialogComponent,
  DynamicFormComponent,
  DynamicInputAOIComponent,
  DynamicInputComponent,
  DynamicInputTOIComponent,
  FooterComponent,
  HelperTextComponent,
  IconButtonComponent,
  InputComponent,
  LayersComponent,
  ListItemComponent,
  MakeOrderComponent,
  MapComponent, MenuServiceDialogComponent,
  NavbarComponent,
  OrdersComponent,
  SearchBarComponent,
  ServiceDetailsComponent,
  ServiceInstructionsComponent,
  ServiceListComponent,
  SidebarButtonComponent,
  SidebarComponent,
  SnackbarComponent,
  SpinnerComponent,
  TimelineComponent,
  IdentifyDialogComponent
} from './components';
import { OutsideClickDirective } from './directives';
import { MapToolsComponent } from './components/menus/map-tools/map-tools.component';
import { SeriesChartComponent } from './components/series-chart/series-chart.component';
import { LayerStyleDialogComponent } from './components/dialogs/layer-style-dialog/layer-style-dialog.component';
import { LoadLayerComponent } from './components/load-layer/load-layer.component';
import { AnimationDialogComponent } from './components/timeline/animation-dialog/animation-dialog.component';
import { LangDropdownComponent } from './components/lang-dropdown/lang-dropdown.component';
import { LayerLegendComponent } from './components/timeline/layer-legend/layer-legend.component';
import { AlertWindowComponent } from './components/alert-window/alert-window.component';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    MapComponent,
    SidebarComponent,
    SidebarButtonComponent,
    ListItemComponent,
    ServiceListComponent,
    SearchBarComponent,
    CheckboxesComponent,
    DefaultDialogComponent,
    OutsideClickDirective,
    MenuServiceDialogComponent,
    MakeOrderComponent,
    ServiceDetailsComponent,
    ServiceInstructionsComponent,
    ButtonComponent,
    SpinnerComponent,
    DatePickerComponent,
    InputComponent,
    SnackbarComponent,
    TimelineComponent,
    DefaultCardComponent,
    LayersComponent,
    OrdersComponent,
    AccordionComponent,
    DynamicInputComponent,
    DynamicFormComponent,
    DynamicInputTOIComponent,
    DynamicInputAOIComponent,
    HelperTextComponent,
    IconButtonComponent,
    IdentifyDialogComponent,
    MapToolsComponent,
    SeriesChartComponent,
    LoadLayerComponent,
    LayerStyleDialogComponent,
    AnimationDialogComponent,
    LangDropdownComponent,
    LayerLegendComponent,
    AlertWindowComponent
  ],
  imports: [
    CommonModule,
    NgxPermissionsModule,
    FontAwesomeModule,
    AngularSvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMyDatePickerModule,
    TranslateModule,
    NgSelectModule
  ],
  exports: [
    CommonModule,
    NgxPermissionsModule,
    NavbarComponent,
    FooterComponent,
    MapComponent,
    SidebarComponent,
    FontAwesomeModule,
    ServiceListComponent,
    SearchBarComponent,
    SnackbarComponent,
    TimelineComponent,
    DynamicFormComponent
  ]
})
export class SharedModule {}
