import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as parse_georaster from 'georaster';
import * as GeoRasterLayer from 'georaster-layer-for-leaflet';
import 'leaflet.vectorgrid';
import { faUpload, faFileArrowUp, faTimes, faArrowsToCircle } from '@fortawesome/free-solid-svg-icons';
import { Layer, SnackbarService, TranslateService } from '@core';

@Component({
  selector: 'app-load-layer',
  templateUrl: './load-layer.component.html',
  styleUrls: ['./load-layer.component.scss']
})
export class LoadLayerComponent implements OnInit {
  @Input() uploadedLayers: Layer[] = [];
  @Output() outputLayer = new EventEmitter<[Layer, any]>();
  @Output() removeLayer = new EventEmitter<Layer>();
  @Output() zoomToLayer = new EventEmitter<Layer>();

  layerUrlText = '';

  faUpload = faUpload;
  faFileArrowUp = faFileArrowUp;
  faTimes = faTimes;
  faArrowsToCircle = faArrowsToCircle;

  constructor(private snackbarService: SnackbarService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  fileChange(file: any) {
    this.createLayerFromFile(file.target.files[0]);
  }

  onBrowseClick(event: any) {
    event.target.value = '';
  }

  private createLayerFromFile(file: File): void {
    const fileNameSplit = file.name.split('.');
    const extension = fileNameSplit[fileNameSplit.length - 1].toLowerCase();
    if (extension === 'tif' || extension === 'tiff' || extension === 'geotiff') {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const s: ArrayBuffer = fileReader.result as ArrayBuffer;
        const a = parse_georaster(s);
        a.then((r: any) => {
          const leafletInstance = new GeoRasterLayer({
            georaster: r
          });
          const layer: Layer = {
            layerName: file.name.slice(0, file.name.lastIndexOf('.')),
            id: file.name,
            layerKey: file.name,
            timesteps: [],
            wmsParams: {
              bbox: `${leafletInstance.getBounds().getSouth()},${leafletInstance.getBounds().getWest()},${leafletInstance.getBounds().getNorth()},${leafletInstance.getBounds().getEast()}`,
              time: '',
              layers: file.name
            },
            geoServerURI: '',
            downloadURI: '',
            orderID: 0,
            dateTime: ''
          }
          this.outputLayer.emit([layer, leafletInstance]);
        });
      };
      fileReader.readAsArrayBuffer(file);
    } else {
      const title = this.translateService.translate('main-page.snackbars.danger.map-tools.invalid-file.title');
      const message = this.translateService.translate('main-page.snackbars.danger.map-tools.invalid-file.message');
      this.snackbarService.danger(title, message).during(5000).show();
    }
    // else if (extension === 'zip') {
    //   const fileReader = new FileReader();
    //   fileReader.onload = (e) => {
    //     const s: ArrayBuffer = fileReader.result as ArrayBuffer;
    //     console.log(s)
    //     const a = shp(s);
    //     a.then(geoJson => {
    //       const leafletInstance: L.GeoJSON = L.geoJSON(geoJson as GeoJsonObject);
    //       const layer: Layer = this.getLayerObject(leafletInstance, file.name);
    //       this.outputLayer.emit([layer, leafletInstance]);
    //     }).catch(error => console.log(error));
    //   };
    //   fileReader.readAsArrayBuffer(file);

    // }
  }

  // private getLayerObject(leafletInstance: any, filename: string): Layer {
  //   let layer: Layer;
  //   layer = {
  //     layerName: filename.slice(0, filename.lastIndexOf('.')),
  //     id: filename,
  //     layerKey: filename,
  //     wmsParams: {
  //       bbox: `${leafletInstance.getBounds().getSouth()},${leafletInstance.getBounds().getWest()},${leafletInstance.getBounds().getNorth()},${leafletInstance.getBounds().getEast()}`,
  //       time: '',
  //       layers: filename,
        
  //     },
  //     geoServerURI: '',
  //     downloadURI: '',
  //     orderID: 0,
  //     dateTime: '',
  //     timesteps: []
  //   };
  //   return layer;
  // }

  readInputUrl() {
    const encodedUrl = this.layerUrlText;
    if(!this.isInputUrlEmpty && this.isUrlValid(encodedUrl)) {
      try {
        const _url = new URL(decodeURIComponent(encodedUrl).toLowerCase());
        const wmsServer = _url.protocol + '//' + _url.hostname + _url.pathname;
        const layerId = _url.searchParams.get('layers');
        let bounds = _url.searchParams.get('bbox');
        const isWMS = _url.searchParams.get('service') === 'wms';
        if (layerId !== null && wmsServer !== null && bounds !== null && isWMS) {
          const boundsAux = bounds.split(',');
          bounds = [boundsAux[1], boundsAux[0], boundsAux[3], boundsAux[2]].join(',');
          const layer: Layer = {
            layerName: layerId,
            id: layerId,
            layerKey: layerId,
            timesteps: [],
            wmsParams: {
              bbox: bounds? bounds : '',
              time: '',
              layers: layerId,
              format: 'image/png'
            },
            geoServerURI: wmsServer,
            downloadURI: '',
            orderID: 0,
            dateTime: ''
          }
          this.outputLayer.emit([layer, undefined]);
        } else {
          const paramsMissing = [(layerId !== null ? undefined : 'layers'),
          (bounds !== null ? undefined : 'bbox'),
          (isWMS ? undefined : 'service=wms')].filter(par => par !== undefined);
          const title = this.translateService.translate('main-page.snackbars.danger.map-tools.param-missing.title');
          const message = this.translateService.translate('main-page.snackbars.danger.map-tools.param-missing.message') + paramsMissing.join(',');
          this.snackbarService.danger(title, message).during(5000).show();
        }
      } catch (error) {
        const title = this.translateService.translate('main-page.snackbars.danger.map-tools.url-error.title');
        const message = this.translateService.translate('main-page.snackbars.danger.map-tools.url-error.message');
        this.snackbarService.danger(title, message).during(5000).show();
      }
    } else {
      const title = this.translateService.translate('main-page.snackbars.danger.map-tools.invalid-url.title');
      const message = this.translateService.translate('main-page.snackbars.danger.map-tools.invalid-url.message');
      this.snackbarService.danger(title, message).during(5000).show();
    }
  }

  isUrlValid(url: string) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[:;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    try {
      return !!pattern.test(url);
    } catch (error) {
      return false;
    }
  }

  get isInputUrlEmpty() {
    return this.layerUrlText === '';
  }

  onRemoveClick(layer: Layer) {
    this.removeLayer.emit(layer);
  }

  onZoomClick(layer: Layer) {
    this.zoomToLayer.emit(layer);
  }

}
