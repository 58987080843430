<div class="flex flex-col mt-2">
  <label [for]="id" class="text-gray-700">{{ label }}</label>
  <input
    [id]="id"
    class="
      mt-1
      w-full
      rounded-md
      bg-gray-100
      border-transparent
      focus:border-gray-500 focus:bg-white focus:ring-0
      py-2
      px-3
    "
    [placeholder]="'placeholders.click-to-select-date' | translate"
    angular-mydatepicker
    [name]="name"
    (click)="dp.toggleCalendar()"
    [(ngModel)]="model"
    [options]="myDpOptions"
    #dp="angular-mydatepicker"
    (dateChanged)="onDateChanged($event)"
  />
</div>
