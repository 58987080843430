<footer
  class="
    z-40
    font-sans
    sm:text-left
    flex
    flex-row
    justify-between
    pt-2
    px-6
    bg-gray-100
    h-14
  "
>
  <div class="flex justify-start gap-4 max-w-[80%] overflow-x-auto">


    <a href="https://elecnor-deimos.com" target="_blank">
      <svg-icon src="assets/img/deimos_green.svg" svgClass="h-9 w-20"></svg-icon>
    </a>
    <a href="https://pml.ac.uk" target="_blank">
      <svg-icon src="assets/img/pml.svg" svgClass="h-9 w-20"></svg-icon>
    </a>
    <a href="https://www.novasbe.unl.pt/en/" target="_blank">
      <svg-icon src="assets/img/nova.svg" svgClass="h-9 w-20"></svg-icon>
    </a>
    <a href="https://www.terradue.com/portal/" target="_blank">
      <svg-icon src="assets/img/tdue.svg" svgClass="h-9 w-20"></svg-icon>
    </a>
    <a href="https://www.sintef.no" target="_blank">
      <svg-icon src="assets/img/sintef.svg" svgClass="h-9 w-20"></svg-icon>
    </a>
    
    <a href="https://www.aircentre.org/" target="_blank">
      <svg-icon src="assets/img/air.svg" svgClass="h-9 w-20"></svg-icon>
    </a>
    <a href="https://www.i2s.gr/" target="_blank">
      <svg-icon src="assets/img/i2s.svg" svgClass="h-9 w-20"></svg-icon>
    </a>
    <a href="https://www.ipma.pt/en/index.html" target="_blank">
      <svg-icon src="assets/img/ipma.svg" svgClass="h-9 w-20"></svg-icon>
    </a>
    <a href="https://www.cls.fr/en/" target="_blank">
      <svg-icon src="assets/img/cls.svg" svgClass="h-9 w-20"></svg-icon>
    </a>
    <a href="https://colabatlantic.com/" target="_blank">
      <svg-icon src="assets/img/colab_atlantic.svg" svgClass="h-9 w-20"></svg-icon>
    </a>
    <a class="min-w-[65px]" href="https://www.amn.pt/Paginas/Homepage.aspx" target="_blank">
      <img src="assets/img/amn.png" width="65px" height="65px">
    </a>
  </div>
  <!-- <div *ngIf="false" class="text-center">
    <div *ngIf="env.production else staging">
      <span>made with ☕️ at <a href="https://elecnor-deimos.com/" target="_blank">&copy; Deimos</a></span>
      <span> - v{{pkg.version}}</span>
    </div>
    <ng-template #staging>
      <a class="items-center justify-center flex gap-4 z-40 bg-danger-500 shadow-inner bottom-7 rounded-full py-1 px-2 hover:px-5"
         href="https://jira.elecnor-deimos.com/projects/SERV4EO?selectedItem=com.atlassian.jira.jira-projects-plugin%3Arelease-page&status=unreleased"
         target="_blank">
        <span class="text-gray-50">🛰 services4eo - v{{pkg["service4eo-version"]}}</span>
        <span class="text-gray-50">⚙️ application - v{{pkg["version"]}}</span>
      </a>
    </ng-template>
  </div> -->

  <div class="flex gap-4 text-gray-700 place-content-end my-auto">
    <p *ngIf="coordinates">
      <span class="text-asset font-bold">
        {{ "main-page.footer.coordinates" | translate }}:
      </span>
      <span>{{ coordinates }}</span>
    </p>
    <p *ngIf="scale">
      <span class="text-asset font-bold">
        {{ "main-page.footer.scale" | translate }}
      </span>
      <span> 1:{{ scale | number: "1.0-0" }}</span>
    </p>
  </div>
</footer>
