<div
  [ngSwitch]="variant"
  class="flex items-center justify-center w-full h-full"
>
  <span *ngSwitchCase="'rounded'">
    <div
      class="spinner ease-linear rounded-full border-8 border-t-8 border-gray-300 h-16 w-16"
    ></div>
  </span>

  <progress
    *ngSwitchCase="'linear'"
    class="linear w-full bg-gray-300 appearance-none h-1"
  ></progress>
</div>
