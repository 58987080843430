function orderBgColorClass(serviceType: string) {
  switch (serviceType) {
    case 'Data_Dissemination_Service':
      return 'bg-blue-300';
    case 'Processing_Service':
      return 'bg-indigo-300';
    case 'Data_Collection':
      return 'bg-purple-300';
    case 'Data_Preparation_Service':
      return 'bg-yellow-300';
    default:
      return 'bg-gray-300';
  }
}

function collapsedClass(collapsed: boolean) {
  return collapsed ? 'transform rotate-0' : 'transform -rotate-90';
}

export { orderBgColorClass, collapsedClass };
