import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  httpOptions,
  IOrderPaginationResponse,
  Job,
  Layers,
  Options,
  Order,
  Output,
  ServiceDescription,
  ServicePeriod,
  TimelineService
} from '@core';
import { map, shareReplay, take } from 'rxjs/operators';
import { serializeParams } from 'shared/helpers/serialize';
import { environment } from '../../../../environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  serviceURL = `${environment.apiEndpoint}/v1/services`;

  orderURL = `${environment.apiEndpoint}/v1/order`;

  checkedLayers: Map<string, Layers> = new Map();

  ordersLoaded$: Subject<boolean> = new Subject();

  constructor(private httpService: HttpClient, private timelineService: TimelineService) {}

  getAllUserOrdersByServiceId(id: string) {
    return this.httpService
      .get<IOrderPaginationResponse>(`${this.serviceURL}/${id}/orders`, httpOptions)
      .pipe(
        take(1),
        map(order => order)
      );
  }

  getAllCatalogueServices(filters?: Options | null) {
    const params = filters ? `?${serializeParams(filters)}` : '';

    return this.httpService.get<any>(`${this.serviceURL}${params}`, httpOptions).pipe(
      take(1),
      map(service => service.services)
    );
  }

  getCatalogueServiceByServiceId(identifier: string) {
    return this.httpService.get<Order>(`${this.serviceURL}/${identifier}`, httpOptions).pipe(
      take(1),
      shareReplay(1),
      map(service => service)
    );
  }

  getServiceDescription(identifier?: string) {
    return this.httpService.get<ServiceDescription>(
      `${this.serviceURL}/${identifier}/description`, httpOptions
    );
  }

  getOrderJobs(identifier?: number) {
    return this.httpService.get<Job[]>(
      `${this.orderURL}/${identifier}/jobs`, httpOptions
    );
  }

  getOrderOutputs(identifier?: number) {
    return this.httpService.get<Output[]>(
      `${this.orderURL}/${identifier}/outputs`, httpOptions
    );
  }

  addCheckedLayer(layerKey: string, orderId: number, layer: Layers) {
    const id = layerKey + orderId.toString();
    this.checkedLayers.set(id, layer);
  }

  removeCheckedLayer(layerKey: string, orderId: number) {
    const id = layerKey + orderId.toString();
    if (this.checkedLayers.has(id)) {
      this.checkedLayers.delete(id);
    }
  }

  isLayerChecked(layerKey: string, orderId: number): boolean {
    const id = layerKey + orderId.toString();
    return this.checkedLayers.has(id);
  }

  getCheckedLayer(layerKey: string, orderId: number): Layers | undefined {
    const id = layerKey + orderId.toString();
    return this.checkedLayers.get(id);
  }

  isTimeSourceSet(layerKey: string, complexOrderId: number): boolean {
    return this.timelineService.isTimeSourceSet(layerKey, complexOrderId);
  }

  getServicePeriod(serviceIdentifier: string): Observable<ServicePeriod> {
    const url = `${this.serviceURL}/${serviceIdentifier}/service-period`;
    return this.httpService.get<ServicePeriod>(url, httpOptions);
  }

  downloadFile(url: string): Observable<any> {
    return this.httpService.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: 'text/plain;charset=UTF-8',
      })
    });
  }
}
