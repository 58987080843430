import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOCALE_ID, TranslateService } from '@core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  lang: string = LOCALE_ID;

  isAuthenticated$ = this.oidcSecurityService.isAuthenticated$.pipe(
    map(auth => auth.isAuthenticated)
  );

  constructor(
    private ngxPermissionService: NgxPermissionsService,
    private translateService: TranslateService,
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private logger: NGXLogger
  ) {
    translateService.currentLanguage = translateService.currentLanguage;
  }

  ngOnInit(): void {
    this.oidcSecurityService
      .checkAuth()
      .pipe(take(1))
      .subscribe(auth => {
        const { isAuthenticated, userData, accessToken } = auth;
        if (userData?.userRole)
          this.ngxPermissionService.loadPermissions([userData.userRole]);
        else this.ngxPermissionService.flushPermissions();

        if (accessToken && !isAuthenticated)
          this.oidcSecurityService.authorize();
      });
  }

  setLang(lang: string) {
    this.translateService.currentLanguage = lang;
  }

  login(): void {
    this.oidcSecurityService.authorize();
  }

  logout(): void {
    this.oidcSecurityService.logoff().pipe(take(1)).subscribe(() => {
      this.ngxPermissionService.flushPermissions();
      this.router.navigate(['/sign-out']);
    })
  }

  ngAfterViewInit(): void {
    this.logger.info(this.oidcSecurityService.getUserData());
  }
}
