import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Provider } from '@core/models';
import { TranslateService } from '@core/services/translate/translate.service';
import { format } from 'date-fns';
import { slugify } from 'shared/helpers';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss']
})
export class ServiceDetailsComponent implements OnInit, OnChanges {
  @Input() description?: string;
  @Input() created?: string;
  @Input() provider?: Provider;
  @Input() type?: string;
  @Input() keywords?: string[];
  @Input() documentationURI?: string;

  slugify = slugify

  constructor(public translateService: TranslateService) {}

  ngOnInit(): void {}

  types(): string[] | undefined {
    return this.type?.split(',').map(type => type.trim());
  }

  ngOnChanges(): void {
    if (this.created) {
      this.created = format(new Date(this.created), 'dd/MM/yyyy');
    }
  }
}
