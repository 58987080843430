export const environment = {
  production: false,
  staging: true,
  apiEndpoint: 'https://api.staging.services4eo.com/request',
  authority: 'https://triple-a.staging.services4eo.com',
  clientId: '22888d9c-88bc-47b3-ad8f-b0dacd8ca23e',
  // clientId: 'c1d64a5a-b11c-45b5-a9e6-170fbc7572b9',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: '4de969a4-083d-48aa-aa22-27478ee8b28b',
  support: 'https://service4eo.atlassian.net/servicedesk',
  storeUrl: 'https://nextocean.staging.services4eo.com/'
};
