import { NgModule } from '@angular/core';
import { LayoutComponent } from './pages/layout/layout.component';
import { SharedModule } from '../../shared';

@NgModule({
  declarations: [LayoutComponent],
  imports: [SharedModule],
  exports: [LayoutComponent],
})
export class MainLayoutModule {}
