import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() variant: 'linear' | 'rounded' = 'rounded';

  constructor() {}

  ngOnInit(): void {}
}
