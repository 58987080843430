import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TimeOfInterestInput } from '../classes';
import { IMyDateRangeModel } from 'angular-mydatepicker';
import { format } from 'date-fns';

@Component({
  selector: 'app-dynamic-input-toi',
  templateUrl: './dynamic-input-toi.component.html',
  styleUrls: ['./dynamic-input-toi.component.scss'],
})
export class DynamicInputTOIComponent implements OnInit {
  @Input() input!: TimeOfInterestInput;
  @Input() form!: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {}

  onDateSelected(dateRange: IMyDateRangeModel) {
    const dateFormat = "yyyy-MM-dd'T'HH:mm:ss";
    if (dateRange?.beginJsDate && dateRange?.endJsDate) {
      let beginDate = format(new Date(dateRange.beginJsDate), dateFormat);
      let endDate = format(new Date(dateRange.endJsDate), dateFormat);
      this.form.get(this.input.key)?.setValue(`${beginDate},${endDate}`);
    }
  }
}
