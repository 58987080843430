<button
  [ngClass]="selectedClass()"
  (mouseover)="isHover = true"
  (mouseleave)="isHover = false"
  (click)="onClick($event)"
  class="
    py-4
    px-4
    transition
    duration-500
    ease-in-out
    hover:bg-asset-500
    hover:text-gray-50
    min-w-full
    {{ classes }}
  "
>
  <fa-icon
    *ngIf="icon"
    [icon]="icon"
    [size]="iconSize"
    [ngClass]="selectedClass()"
  ></fa-icon>
  <label
    *ngIf="label"
    class="hover:cursor-pointer"
    [ngClass]="labelClasses"
    (click)="isHover = !isHover"
  >
    {{ label }}</label
  >
</button>
