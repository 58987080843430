<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div *ngFor="let input of inputs">
      <ng-container [ngSwitch]="input.controlType">
        <app-dynamic-input-toi
          *ngSwitchCase="'TOI'"
          [form]="form"
          [input]="input"
        ></app-dynamic-input-toi>
        <app-dynamic-input-aoi
          *ngSwitchCase="'AOI'"
          [form]="form"
          [input]="input"
          [allowedGeometry]="allowedGeometry"
        >
        </app-dynamic-input-aoi>
        <app-dynamic-input
          *ngSwitchDefault
          [form]="form"
          [input]="input"
        ></app-dynamic-input>
      </ng-container>
    </div>

    <div class="flex justify-end mt-4">
      <app-button
        iconClass="animate-spin"
        [leftIcon]="isLoading ? faCircleNotch : undefined"
        [isDisabled]="!form.valid || isLoading"
        label="{{'actions.order' | translate }}"
        type="submit"
      ></app-button>
    </div>
  </form>
</div>
