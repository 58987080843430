import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'default-dialog',
  templateUrl: './default-dialog.component.html',
  styleUrls: ['./default-dialog.component.scss'],
})
export class DefaultDialogComponent implements OnInit {
  dialogSize = '';
  convertSize: { [key: string]: string } = {
    sm: 'sm:w-5/12 md:w-4/12 lg:w-3/12',
    md: 'sm:w-7/12 md:w-6/12 lg:w-6/12',
    lg: 'sm:w-9/12 md:w-8/12 lg:w-9/12',
    xl: 'sm:w-11/12 md:w-10/12 lg:w-11/12',
  };

  faCheck = faCheck;
  faTimes = faTimes;

  @Input() open: boolean = false;
  @Input() classes?: string;
  @Input() noConfirmButton: boolean = false;
  @Input() title?: string;
  @Input() confirmText?: string;
  @Input() confirmDisabled: boolean = false;
  @Input() cancelText?: string;
  @Input() size = 'md';

  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.dialogSize = this.convertSize[this.size];
  }

  onConfirm() {
    this.confirm.emit();
  }

  onCancel() {
    this.cancel.emit();
  }

  dialogClasses(){
    return this.convertSize[this.size];
  }
}
