<div *ngIf="showLegend"  #legendBounds>
    <div
      class="legend-box"
      *ngIf="imageURL"
    >
      <img
        [src]="imageURL"
        [alt]=""
        #image
        placement="left"
        container="body"/>
	    <div class="data-box">
				<p *ngFor="let scale of scaleRange">{{scale | number:'.2-2'}}</p>
	    </div>
	    <div class="data-box-unit" *ngIf="scaleRange && scaleRange.length > 0">
		    <p>{{scaleUnit}}</p>
	    </div>
    </div>

  
</div>
<div *ngIf="showGradient" style="width: 100%; height: 2em">
  <div class="shape-colorbar" [ngStyle]="{'background-image': gradient}"></div>
</div>