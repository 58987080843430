<div
  *ngIf="open"
  class="z-50 absolute right-3 bottom-20 p-4 bg-white rounded-lg shadow-lg opacity-90 mxH"
  [ngClass]="dialogClasses()"
>
  <div class="flex justify-end -mr-2">
    <app-icon-button
      [icon]="faTimes"
      iconClass="hover:text-red-500"
      (clicked)="onCancel()"
    >
    </app-icon-button>
  </div>

  <div
    class="
        inline-block
        align-bottom
        bg-white
        rounded-lg
        text-left
        overflow-hidden
        transform
        transition-all
        sm:my-2 sm:align-middle
        w-full
      "
  >
    <div
      *ngIf="title"
      class="mb-4 mx-auto items-center justify-center text-center font-bold"
    >
      {{ title }}
    </div>
    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
      <div class="mt-2">
        <p class="text-sm text-gray-500">
          <ng-content></ng-content>
        </p>
      </div>
    </div>
  </div>
</div>
