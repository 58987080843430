import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IMyDateRangeModel } from 'angular-mydatepicker';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-animation-dialog',
  templateUrl: './animation-dialog.component.html',
  styleUrls: ['./animation-dialog.component.scss']
})
export class AnimationDialogComponent implements OnInit {

  @Output() timeInterval = new EventEmitter<[Date, Date, number]>();
  @Output() cancel = new EventEmitter();

  faTimes = faTimes;

  frameRate = 5;

  beginDate?: Date;
  endDate?: Date;

  constructor() { }

  ngOnInit(): void {
  }

  onDateSelected(dateRange: IMyDateRangeModel) {
    if (dateRange?.beginJsDate && dateRange?.endJsDate) {
      this.beginDate = new Date(dateRange.beginJsDate);
      this.endDate = new Date(dateRange.endJsDate);

    }
  }

  onCancel() {
    this.cancel.emit();
  }

  onSubmit() {
    if (this.beginDate && this.endDate && this.frameRate) this.timeInterval.emit([this.beginDate, this.endDate, this.frameRate]);
  }

  get submitDisabled() {
    return !(this.beginDate && this.endDate && this.frameRate);
  }

}
