import { Component, Input, OnDestroy,
  OnInit, Output, EventEmitter, } from '@angular/core';
import { FeatureInfoResponse, MapService, TranslateService } from '@core';
import { Subscription } from 'rxjs';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-identify-dialog',
  templateUrl: './identify-dialog.component.html',
  styleUrls: ['./identify-dialog.component.scss']
})
export class IdentifyDialogComponent implements OnInit, OnDestroy {

  @Output() cancel = new EventEmitter();

  identifySub: Subscription;
  featureInfo?: FeatureInfoResponse;

  faTimes = faTimes;

  constructor(private mapService: MapService) { 
    this.identifySub = this.mapService.featureInfoCompleted$.subscribe((featureInfo) => {
      this.featureInfo = featureInfo;
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.identifySub.unsubscribe()
  }

  get hasFeatureInfo() {
    return this.featureInfo !== undefined;
  }

  getMapKeys(map: Map<string, string | number>) {
    return Array.from(map.keys());
  }

  getFormattedDatetime(dateString: string) {
    return new Date(dateString);
  }

  onCancel() {
    this.featureInfo = undefined;
    this.cancel.emit();
  }

}
