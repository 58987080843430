import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../../../environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.authority,
        redirectUrl: `${window.location.origin}/callback`,
        postLogoutRedirectUri: `${window.location.origin}/sign-out`,
        clientId: environment.clientId,
        scope: environment.scope,
        responseType: 'id_token token'
      }
    })
  ],
  exports: [AuthModule],
  providers: []
})
export class AuthConfigModule {}
